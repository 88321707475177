import CodeIcon from '@mui/icons-material/Code';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { viewerStore } from '../../../stores/ViewerStore';
import { useEmbed } from '../hooks/useEmbed';
import { useShare } from '../hooks/useShare';
import { useSnapshot } from '../hooks/useSnapshot';

export const AppBarMoreButton: React.FC = () => {
    const [t] = useTranslation();
    const [open, setOpen] = React.useState(false);
    const ref = React.useRef(null);

    const { viewerVisible } = viewerStore.state;
    const disabled: boolean = !viewerVisible;
    const handleShare = useShare();
    const handleEmbed = useEmbed();
    const handleSnapshot = useSnapshot();

    return (
        <>
            <IconButton ref={ref} sx={{color: '#FFFFFF'}}  onClick={() => setOpen(true)}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={ref.current}
                open={open}
                onClose={()=> setOpen(false)}
            >
                <MenuItem
                    onClick={handleShare}
                    disabled={disabled}
                >
                    <ShareIcon style={{color: '#868686'}} />
                    <ListItemText primary={t('Share')} sx={{paddingLeft: '10px'}}/>
                </MenuItem>
                <MenuItem
                    onClick={handleEmbed}
                    disabled={disabled}
                >
                    <CodeIcon  style={{color: '#868686'}} />
                    <ListItemText primary={t('Embed')} sx={{paddingLeft: '10px'}}/>
                </MenuItem>
                <MenuItem
                    onClick={handleSnapshot}
                    disabled={disabled}
                >
                    <PhotoCameraIcon style={{color: '#868686'}} />
                    <ListItemText  primary={t('Snapshot')} sx={{paddingLeft: '10px'}}/>
                </MenuItem>
            </Menu>
        </>
    );
};
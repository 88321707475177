import type * as React from 'react';

interface WrapperProps {
    className?: string;
    children?: React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ className, children }) => {
    let l1ClassName: string = '';

    if (className) {
        l1ClassName += ' ' + className;
    }

    return (
        <div className={'wrapper-l1' + l1ClassName}>
            <div className="wrapper-l2">
                <div className="wrapper-l3">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Wrapper;

import type { MakeStylesHook, StyleRules } from '@egr/xbox/utils/tss-react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { appStore } from '../stores/AppStore';
import theme from '../styles/theme';

import { makeStyles } from '@egr/xbox/utils/tss-react';

type FAQClasses = 'root' | 'root1' | 'headline' | 'text';

function getFAQStyles(): StyleRules<FAQClasses> {
    return {
        root: {
            position: 'absolute',
            zIndex: 10,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#F9F9F9',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch'
        },
        root1: {
            flex: '0 1 auto',
            width: 800,
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingBottom: 32,
            paddingTop: 32
        },
        headline: {
            fontSize: 24,
            fontWeight: 400,
        },
        text: {
            fontSize: 14,
            '& p': {
                margin: 0
            },
            '& p + p': {
                marginTop: '1em'
            },
        },
        [theme.breakpoints.down('sm')]: {
            root1: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2)
            }
        }
    };
}

const useFAQClasses: MakeStylesHook<FAQClasses> = makeStyles({ name: 'FAQ' })(getFAQStyles);
const FAQ: React.FC = () => {
    const { t } = useTranslation();
    const {classes} = useFAQClasses();
    const {faq} = appStore.state;

    useEffect(() => {
        if (faq) {
            history.pushState(null, '');
            window.onpopstate = function (event: PopStateEvent) {
                appStore.state.faq = false;
            };
        } else {
            window.onpopstate = function (event: PopStateEvent) {
                // nothing
            };
        }
    }, [faq]);

    if (!faq) {
        return null;
    }

    return (
        <div className={classes.root}>
            <div className={classes.root1}>
                <h1 className={classes.headline}>{t('FAQ')}</h1>
                <div>
                    {[1, 2, 3, 4, 6, 7, 8, 9].map((n) => (
                        <Accordion key={'panel' + n}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.headline}>{t('faqQuestion' + n)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div
                                    className={classes.text}
                                    dangerouslySetInnerHTML={{ __html: t('faqAnswer' + n) }}
                                />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQ;

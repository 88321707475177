import type * as React from 'react';

interface ImageWrapperProps extends React.HTMLAttributes<HTMLDivElement>{
    src: string;
}

export const ImageWrapper: React.FC<ImageWrapperProps> = (props) => {
    const { src, ...divProps } = props;
    return (
        <div {...divProps}>
            <img style={{maxHeight: '100%', maxWidth: '100%'}} src={src}/>
        </div>
    );
};
import { assert } from './Debug';
import { getEnvVar } from './ReactScriptHelper';
import { buildQueryString } from './Url';

import { isNumber } from '@easterngraphics/wcf/modules/utils/number';
import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

const CORS_PROXY: string = getEnvVar('CORS_PROXY', 'https://pi.pcon-solutions.com/pi_proxy/');
const CORS_REGEX: RegExp = new RegExp(getEnvVar('CORS_REGEX', '^http[s]?://(?!web\.cadse\.egr).*'));

export function corsProxyUrl(src: string, fallback: null): string | null;
export function corsProxyUrl(src: string, fallback?: string): string;
export function corsProxyUrl(src: string, fallback: string | null = src): string | null {
    if (
        src.startsWith('http') && // ignore `blob:` and other custom protocols
        isNotNullOrEmpty(CORS_PROXY) &&
        CORS_REGEX.test(src)
    ) {
        return `${CORS_PROXY}${encodeURIComponent(src)}`;
    }

    return fallback;
}

type ImageScaleCropType = 'c' | 'b';
const IMAGE_SCALE_API: string = getEnvVar('IMAGE_SCALE_API', 'https://pi.pcon-solutions.com/imgscaler/');

export function getScaledImageUrl(
    width: number | undefined,
    height: number | undefined,
    url: string,
    cropType?: ImageScaleCropType,
    format?: string
): string {
    if (
        isNotNullOrEmpty(IMAGE_SCALE_API) &&
        isNotNullOrEmpty(url) &&
        assert(!url.startsWith(IMAGE_SCALE_API), 'already `scaled` url provided')
    ) {
        return IMAGE_SCALE_API + '?' + buildQueryString(
            {
                w: isNumber(width) ? Math.round(width) : '',
                h: isNumber(height) ? Math.round(height) : '',
                c: cropType,
                f: format,
                u: url
            },
            true
        );
    }

    return url;
}
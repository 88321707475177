import type { FileExtension} from '../utils/UrlInfo';

import i18next from 'i18next';
import * as React from 'react';

import {appStore} from '../stores/AppStore';
import ClipboardDialogStore from '../stores/ClipboardDialogStore';
import { viewerStore } from '../stores/ViewerStore';
import { isEgrFileService_AR_MultiFileLink } from '../utils/UrlInfo';
import { getEmbedUrl } from '../utils/utils';

import { isBrowserARAvailable } from '@egr/xbox/AR/Availability'
import { android, inIframe, ios } from '@egr/xbox/utils/UserAgent';

const ViewerAr: React.FC = () => {
    const urlInfo = appStore.state.urlInfo!;

    if (urlInfo.extension !== 'glb' && urlInfo.extension !== 'usdz' && urlInfo.extension !== 'iar' && urlInfo.extension !== 'gltf') {
        return null;
    }
    React.useEffect(
        () => {
            void openARLink();
        } ,
        []
    );
    return <></>;
};

export default ViewerAr;

export function deviceSupportsFileExtension(ext: FileExtension): boolean {
    if (ext === 'iar') {
        return ios || android;
    }
    if (ext === 'usdz') {
        return ios;
    }
    if (ext === 'glb' || ext === 'gltf') {
        return android;
    }
    return false;
}

export async function openARLink(): Promise<void> {
    if (appStore.snapshot.urlInfo == null) {
        return;
    }
    const urlInfo = appStore.snapshot.urlInfo;
    const ext: FileExtension = urlInfo.extension;
    const url: string = urlInfo.fileDownloadUrl;

    const text: {android: string, iOS: string, default: string} = {
        android: i18next.t('Share AR link for Android'),
        iOS: i18next.t('Share AR link for iOS'),
        default: i18next.t('Augmented Reality') // 'Share AR link for iOS/Android'
    };
    const displayDefault: boolean = isEgrFileService_AR_MultiFileLink(urlInfo.fileUrl); // url from browser or Impress address bar
    const messages: { android: string, iOS: string, pc: string } = {
        android: displayDefault ? text.default : text.android,
        iOS: displayDefault ? text.default : text.iOS,
        pc: displayDefault ? text.default : ext === 'usdz' ? text.iOS
            : (ext === 'glb' || ext === 'gltf') ? text.android : text.default
    };

    if (isBrowserARAvailable && deviceSupportsFileExtension(ext)) {
        console.warn('DeviceHasHandler & supports the extension, url/ext: ', url, ext);
        if (ext === 'usdz' || url.endsWith('.usdz')) { // urlInImpressServer has already usdz extension
            await openARLinkiOS(url, messages.iOS);
            return;
        }
        if (ext === 'glb' || ext === 'gltf' || url.endsWith('.gltf') || url.endsWith('.glb')) {
            // urlInImpressServer has already gltf extension
            await openARLinkAndroid(url, messages.android);
            return;
        }
    } else {
        console.warn('Device Has NO Handler, or no support for ext, url/ext: ', url, ext);
        const content: string = getEmbedUrl(urlInfo.fileUrl, false); //     location.href; // INPUT files require code!!!
        void displayClipboardDialog(messages.pc, content, '');
        return;
    }
}

async function openARLinkAndroid(url: string, message: string) {
    if (appStore.snapshot.urlInfo == null) {
        return;
    }
    url = url.replace('http://', 'https://');
    if (android) {
        const fallbackurl: string = '';
        const anchor: HTMLAnchorElement = document.createElement('a');
        let href: string = 'intent://arvr.google.com/scene-viewer/1.0?file={file}&mode=ar_preferred#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url={fallbackurl};end;';

        href = href.replace('{file}', encodeURIComponent(url));
        href = href.replace('{fallbackurl}', encodeURIComponent(fallbackurl));

        anchor.href = href;
        document.body.appendChild(anchor);

        const content: string = getEmbedUrl(appStore.snapshot.urlInfo.fileUrl, false);
        void displayClipboardDialog(message, content, href);
        anchor.click();
    }
}

async function openARLinkiOS(url: string, message: string) {
    if (appStore.snapshot.urlInfo == null) {
        return;
    }
    const content: string = getEmbedUrl(appStore.snapshot.urlInfo.fileUrl, false);
    void displayClipboardDialog(message, content, url);

    const img: HTMLImageElement = document.createElement('img');
    img.src = 'favicon.ico';
    const anchor: HTMLAnchorElement = document.createElement('a');
    anchor.href = url;
    anchor.rel = 'ar';
    anchor.appendChild(img);
    document.body.appendChild(anchor);
    anchor.click();
}

async function displayClipboardDialog(message: string, content: string, urlInServer: string): Promise<void> {
    await ClipboardDialogStore.open(message, content, true, urlInServer).then(
        () => {
            if (!inIframe()) { location.href = window.location.href.split('?')[0]; }
        }
    );
    viewerStore.setViewerVisible(true);
}

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@egr/xbox/utils/tss-react';

interface SlideProps {
  imgPath: string;
  title: string;
  text: string;
}

const useStyles = makeStyles({ name: 'Slide' })({
  imageWrapper: {
    textAlign: 'center',
  },
  image: {
    width: '100%',
    maxHeight: '282px',
    objectFit: 'cover',
  },
  textContent: {
    marginTop: '46px',
    marginBottom: '46px',
    marginRight: '24px',
    marginLeft: '24px',
    textAlign: 'center',
  },
});

const Slide: React.FC<SlideProps> = ({ imgPath, title, text }) => {
  const {classes} = useStyles();
  const {t} = useTranslation();

  return (
    <div className={classes.imageWrapper}>
      <img src={imgPath} alt={title} className={classes.image} />
      <div className={classes.textContent}>
        <Typography
          variant="h6"
          sx={{
            marginBottom: '22px',
            fontSize: '24px',
            color: '#F7A600',
            marginTop: '0px',
          }}
        >
          {t(title)}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            marginTop: '0px',
            marginBottom: '0px',
            fontSize: '14px',
          }}
        >
          {t(text)}
        </Typography>
      </div>
    </div>
  );
};

export default Slide;

import { SyncExternalStore } from '@egr/xbox/utils/SyncExternalStore';

interface ToastState {
  open: boolean;
  message: string;
}
class ToastStore extends SyncExternalStore<ToastState> {
  constructor() {
    super({
      open: false,
      message: '',
    });
  }
  public message(): string {
    return this.snapshot.message;
  }

  public isOpen(): boolean {
    return this.snapshot.open;
  }

  public open(message: string): void {
    this.updateState({
      message: message,
      open: true,
    });
  }

  public close(): void {
    this.updateState({
      open: false,
    });
  }
}

export default new ToastStore();

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MobileStepper from '@mui/material/MobileStepper';
import { useTranslation } from 'react-i18next';

import theme from '../../styles/theme';

import { makeStyles } from '@egr/xbox/utils/tss-react';

interface StepperNavigationProps {
  steps: number;
  activeStep: number;
  onBack: () => void;
  onNext: () => void;
  onClose: () => void;
}
const useStyles = makeStyles({ name: 'CustomMobileStepper' })({
  buttonContainer: {
    display: 'block',
    flexBasis: '40%',
    textAlign: 'right',
  },
  leftButtonContainer: {
    display: 'block',
    flexBasis: '40%',
    textAlign: 'left',
  },
});

const CustomMobileStepper: React.FC<StepperNavigationProps> = ({ steps, activeStep, onBack, onNext, onClose }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <MobileStepper
      steps={steps}
      position="static"
      activeStep={activeStep}
      nextButton={
        activeStep === steps - 1 ? (
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={onClose}
              size="small"
              sx={{ backgroundColor: theme.palette.secondary.main }}
            >
              {t('Get Started!')}
            </Button>
          </div>
        ) : (
          <div className={classes.buttonContainer}>
            <IconButton onClick={onNext} disabled={activeStep === steps - 1}>
              <KeyboardArrowRight />
            </IconButton>
          </div>
        )
      }
      backButton={
        <div className={classes.leftButtonContainer}>
          <IconButton onClick={onBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
          </IconButton>
        </div>
      }
    />
  );
};

export default CustomMobileStepper;

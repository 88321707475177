import type { CookieUserSetting } from '@egr/xbox/egr-gui-dialogs/cookieDialog/CookieUserSettingManager';

import bind from 'bind-decorator';
import { computed, makeObservable } from 'mobx';
import * as React from 'react';

import { cookieStore } from './CookiesVisibilityStore';
import {visibilityDialogIframeStore} from './StoreVisibilityDialogIFrame';

type IframeBarStyle = 'default' | 'justClose' | 'hidden';

interface LoadingDialogConfiguration {
    label: string;
    closeCallback: () => void;
    value?: number | string;
}

interface DialogManagerState {
    loadingDialog: LoadingDialogConfiguration | undefined;

    cookieDialog: boolean;
    resolveCookieDialog: null | ((value: CookieUserSetting) => void);

    iframeUri: string | undefined;
    iframeTitle: string;
    iframeBarStyle: IframeBarStyle;

    showDialogFullscreen: boolean;
}

class DialogManager extends React.Component<{}, DialogManagerState> {
    @computed
    public get dialogVisible(): boolean {
        return (
            this.state.loadingDialog != null
        );
    }

    constructor() {
        super({});
        makeObservable(this);
        this.onResize = this.onResize.bind(this);
        this.updateState = this.updateState.bind(this);

        window.addEventListener('resize', this.onResize);

        this.state =  this.getInitialState();
    }

    public updateState(o: Object) {

        if (this.state !== null) {
            const clone: DialogManagerState = Object.assign(this.state, o);
            // this.setState(clone);
            this.setState(clone);

            cookieStore.setVisibility(this.state.cookieDialog === true);
            visibilityDialogIframeStore.setVisibility((this.state.iframeUri !== undefined));
        }

    }

    public showIframe = (iframeUri: string, iframeTitle: string = '', iframeBarStyle: IframeBarStyle = 'default'): void => {
        this.updateState({iframeUri, iframeTitle, iframeBarStyle});
    };

    public hideIframe = (): void => {
        this.updateState({iframeUri: undefined});
    };

    @bind
    public async showCookieDialog(): Promise<CookieUserSetting> {

        return new Promise<CookieUserSetting>((resolveCookieDialog) => {
            this.updateState({
                cookieDialog: true,
                resolveCookieDialog,
            });
        });
    }

    @bind
    public closeCookieDialog(): void {
        this.updateState({cookieDialog: false, resolveCookieDialog: null});
    }

    protected onResize(): void {
        this.updateState({showDialogFullscreen: this.fullscreenCondition()});
    }

    protected fullscreenCondition(): boolean {
        return window.innerWidth <= 568 || window.innerHeight <= 568;
    }

    protected getInitialState(): DialogManagerState {
        return {
            loadingDialog: undefined,

            iframeUri: undefined,
            iframeTitle: '',
            iframeBarStyle: 'default',

            showDialogFullscreen: this.fullscreenCondition(),

            cookieDialog: false, // false,
            resolveCookieDialog:  null  // this.resolveCookieDialog, //
        };
    }
}

export const dialogManager = new DialogManager();
import * as React from 'react';

import { CookieDialog } from './cookieDialog/CookieDialog';
import { ModalIframe } from './iframe/ModalIframe';

export const DefaultDialogs: React.FC = React.memo(
    () => {
        return (
            <React.Fragment>
                <ModalIframe key="modaliframe" />
                <CookieDialog key="cookiedialog" />
            </React.Fragment>
        );
    }
);
import { SyncExternalStore } from '@egr/xbox/utils/SyncExternalStore';
interface SnapshotDialogState {
  show: boolean;
  title: string;
  snapshot: Blob;
}

class SnapshotDialogStore extends SyncExternalStore<SnapshotDialogState> {
  constructor() {
    super({ show: false, title: '', snapshot: new Blob() });
  }

  public open(title: string, snapshot: Blob): void {
    this.updateState({
      show: true,
      title: title,
      snapshot: snapshot,
    });
  }

  public close(): void {
    this.updateState({
      show: false,
    });
  }
}

export const snapshotDialogStore = new SnapshotDialogStore();

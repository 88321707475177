import type { StyleRules, MakeStylesHook } from '@egr/xbox/utils/tss-react';
import type { Theme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

import { appStore } from '../stores/AppStore';
import ClipboardDialogStore from '../stores/ClipboardDialogStore';
import ToastStore from '../stores/ToastStore';
import { TelemetryTrackEvent } from '../utils/Telemetry';
import { deviceSupportsFileExtension, openARLink } from '../viewers/ViewerAR'; // , openARLink

import { isBrowserARAvailable } from '@egr/xbox/AR/Availability'
import Dialog from '@egr/xbox/egr-gui-dialogs/utils/DialogOverride';
import { inIframe } from '@egr/xbox/utils/UserAgent';
import { makeStyles } from '@egr/xbox/utils/tss-react';

type ClipboardDialogARClasses = 'code' | 'paper' | 'close';

function styles(theme: Theme): StyleRules<ClipboardDialogARClasses> {
    return {
        code: {
            backgroundColor: theme.palette.grey[200],
            borderRadius: 2,
            display: 'block',
            padding: 16,
            wordBreak: 'break-all',
            lineHeight: '1.5em',
            outline: 0
        },
        paper: {
            maxWidth: 700, // 480
            minHeight: 350, // 325
            height: 'fit-content'
        },
        close: {
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
            cursor: 'pointer',
            zIndex: 10
        }
    };
}
const useStyles: MakeStylesHook<ClipboardDialogARClasses> = makeStyles({ name: 'ClipboardDialogAR' })(styles);

const ClipboardDialogAR: React.FC = () => {
        const { classes } = useStyles();
        const { t } = useTranslation();
        const {showQR, show} = ClipboardDialogStore.state;
        const { urlInfo } = appStore.state;
        const copyToClipboard = (): void => {
            if (navigator.clipboard) {
                if (urlInfo != null) {
                    void navigator.clipboard.writeText(urlInfo.fileUrl);
                    ToastStore.open(t('Copied to clipboard'));
                    TelemetryTrackEvent('Embed content');
                }
            }
        };
        if (urlInfo == null) {
            return null;
        }
        const showArOpenButton: boolean = showQR && isBrowserARAvailable && deviceSupportsFileExtension(urlInfo.extension);
        const showCloseButton: boolean = showQR && inIframe() ? false : true;
        const validUntil: string = urlInfo.validUntil ? (new Date(urlInfo.validUntil)).toLocaleDateString() : '';
        const isExpired: boolean = urlInfo.validUntil ? urlInfo.validUntil < Date.now() : false;
        return (
            <Dialog
                classes={{ paper: classes?.paper }}
                open={show}
                onClose={() => { ClipboardDialogStore.close(); }}
            >
                <IconButton
                    style={{ display: showCloseButton ? 'block' : 'none' }}
                    className={classes?.close}
                    onClick={() => { ClipboardDialogStore.close(); }}
                >
                    <CloseIcon />
                </IconButton>
                <div style={{ position: 'relative', width: '700px', minHeight: '350px', maxWidth: '100%', overflow: 'hidden' }} >
                    <div
                        style={{ display: 'inline-block', position: 'relative', width: '48%', minHeight: 'inherit', backgroundColor: 'rgb(248,248,248)' }}
                    >
                        <div
                            style={{
                                position: 'absolute', backgroundColor: 'transparent', width: '100%', top: '50%', left: '50%',
                                maxWidth: '200px', maxHeight: '200px',
                                transform: 'translate(-50%, -50%)', textAlign: 'center'
                            }}
                        >

                            <QRCode style={{ maxWidth: '100%', opacity: isExpired ? 0.1 : 'unset' }} value={urlInfo.fileUrl} renderAs={'svg'} size={200} />
                            <div style={{ color: (isExpired ? 'red' : 'gray'), display: (validUntil.length >= 8 ? 'block' : 'none') }}>
                                {t('Available until') + ': ' + validUntil}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'inline-block', width: '52%', minHeight: 'inherit', verticalAlign: 'top' }}>
                        <div style={{ margin: '10%', width: '80%' }}>
                            <div style={{ fontSize: '21px', marginTop: '22%' }}>{t('Showing in the room (AR)')}</div>
                            <div style={{ fontSize: '14px', marginTop: '5%' }}>{t('Augmented Reality is possible...')}</div>
                            <div style={{ textAlign: 'center' }}>
                                <div
                                    style={{
                                        cursor: 'pointer', position: 'relative', backgroundColor: 'black', borderRadius: '4px', width: '150px', height: '40px',
                                        margin: 'auto', marginTop: '10%'
                                    }}
                                >
                                    <div
                                        onClick={copyToClipboard}
                                        style={{ color: 'white', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '14px', fontWeight: 'bold' }}
                                    >
                                        {t('Copy')}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: (showArOpenButton ? 'block' : 'none'),
                                        cursor: 'pointer', position: 'relative', backgroundColor: 'black', borderRadius: '4px', width: '150px', height: '40px',
                                        margin: 'auto', marginTop: '10%'
                                    }}
                                >
                                    <div
                                        onClick={() => {
                                            void openARLink();
                                        }}
                                        style={{ color: 'white', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '14px', fontWeight: 'bold' }}
                                    >
                                        {t('Open')}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </Dialog>
        );
};

export default ClipboardDialogAR;
import i18next from 'i18next';

import { captureException } from '@egr/xbox/utils/errors/Error';
export abstract class CustomError extends Error {
    public description: string;
    public embedMessage: string;
    public embedDescription: string;

    constructor(message?: string) {
        super(message);
        this.embedMessage = i18next.t('Error');
        this.embedDescription = i18next.t('This content is not available (anymore).');

        Object.setPrototypeOf(this, CustomError.prototype);
    }
}

export class InvalidLinkError extends CustomError {
    constructor() {
        super(i18next.t('Invalid link'));

        Object.setPrototypeOf(this, InvalidLinkError.prototype);
        this.description = i18next.t('Please enter a valid URL. You can use a public link generated by your cloud service (Dropbox, Google Drive, OneDrive).');
    }
}

export class UnsupportedContentTypeError extends CustomError {
    constructor() {
        super(i18next.t('Content type not supported'));
        Object.setPrototypeOf(this, UnsupportedContentTypeError.prototype);
        this.description = i18next.t('This Format is not supported by impress. Please make sure to use PEC, JPG, JPEG, PNG, EIMG and EPAN files.');
    }
}

export class UnauthorizedError extends CustomError {
    constructor() {
        super(i18next.t('Unauthorized call'));
        Object.setPrototypeOf(this, UnauthorizedError.prototype);
        this.description = i18next.t('Please make sure that the file link is public and try again.');
    }
}

export class FileNotFoundError extends CustomError {
    constructor(isOWDS?: boolean) {
        super(isOWDS ? i18next.t('Content has expired') : i18next.t('File not found'));
        Object.setPrototypeOf(this, FileNotFoundError.prototype);
        this.description = isOWDS ? i18next.t('The content has expired on the server and is no longer available.') : i18next.t('Please make sure that the content is still available in your cloud service.');
    }
}

export class FileDownloadError extends CustomError {
    constructor() {
        super(i18next.t('Failed to download file'));
        Object.setPrototypeOf(this, FileDownloadError.prototype);
        this.description = i18next.t('The file cannot be loaded.');
    }
}

export class InsertError extends CustomError {
    constructor() {
        super(i18next.t('Unable to insert file'));
        Object.setPrototypeOf(this, InsertError.prototype);
        this.description = i18next.t('The content cannot be opened in the viewer. Please make sure that the file is not broken and try again.');
        captureException(this, 'error');
    }
}

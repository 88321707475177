import { assert } from './Debug';
import { getFileList } from './File';
import { ImageFormat, isImageMimetype } from './FileFormats';
import { isNullOrZero } from './Number';
import { corsProxyUrl, getScaledImageUrl } from './Services';
import { safari } from './UserAgent';
import { viewStartsWith } from './View';

import { fromDataUri } from '@easterngraphics/wcf/modules/utils/arraybuffer';
import { isNullOrEmpty, isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

export function loadImage(src: string, tryViaProxyOnError: boolean = true): Promise<HTMLImageElement | null> {
    return new Promise<HTMLImageElement | null>(
        (
            resolve: (value: Promise<HTMLImageElement | null> | HTMLImageElement | null) => void,
            reject: (error: Error) => void
        ): void => {
            const image: HTMLImageElement = document.createElement('img');
            image.addEventListener(
                'load',
                (): void => {
                    resolve(image);
                }
            );
            image.addEventListener(
                'error',
                (errorEvent: ErrorEvent): void => {
                    const corsUrl: string | null = corsProxyUrl(src);
                    if (tryViaProxyOnError && corsUrl != null) {
                        resolve(loadImage(corsUrl, false));
                    } else {
                        resolve(null);
                    }
                }
            );
            image.crossOrigin = 'anonymous';
            image.src = src;
        }
    );
}

export interface ImageSize {
    width: number;
    height: number;
}

export interface ImagePosition {
    x: number;
    y: number;
}

/**
 * Loads an url in a temporary img element to determine the image size.
 * If something fails, null is returned.
 * @param data
 */
export async function getImageSize(src: string): Promise<ImageSize | null> {
    try {
        const imageElement: HTMLImageElement | null = await loadImage(src);
        return imageElement ? {
            width: imageElement.naturalWidth,
            height: imageElement.naturalHeight
        } : null;
    } catch {
        return null;
    }
}

/**
 * Creates a object url from an ArrayBuffer
 * @param data ArrayBuffer
 */
export function getObjectUrl(data: ArrayBuffer, type?: string): string {
    // in Edge18 new Blob(foo, undefined) throws error
    if (isNotNullOrEmpty(type)) {
        return URL.createObjectURL(
            new Blob(
                [new Uint8Array(data)],
                { type }
            )
        );
    }

    return URL.createObjectURL(
        new Blob([new Uint8Array(data)])
    );
}

export function getScaledImageRatio(src: ImageSize, target: Partial<ImageSize>): number {
    if (isNullOrZero(target.width) && isNullOrZero(target.height)) {
        return 1;
    }

    const expectedHeight: number = target.height === 0 ? src.height : (target.height ?? src.height);
    const expectedWidth: number = target.width === 0 ? src.width : (target.width ?? src.width);

    if (isNullOrZero(target.width)) {
        return expectedHeight / src.height;
    }

    if (isNullOrZero(target.height)) {
        return expectedWidth / src.width;
    }

    return Math.min(expectedHeight / src.height, expectedWidth / src.width);
}

export function getScaledImageSize(src: ImageSize, target: Partial<ImageSize>): ImageSize {
    const scaleRatio: number = getScaledImageRatio(src, target);
    return scaleImageSize(src, scaleRatio);
}

function scaleImageSize(src: ImageSize, scaleRatio: number): ImageSize {
    return {
        width: Math.round(src.width * scaleRatio),
        height: Math.round(src.height * scaleRatio)
    };
}

const knownImageFormats: ReadonlyArray<ImageFormat> = [ImageFormat.JPG, ImageFormat.PNG, ImageFormat.GIF];
export function detectImageType(content: ArrayBuffer): ImageFormat | null {
    const view: Uint8Array = new Uint8Array(content);
    for (const format of knownImageFormats) {
        if (format.magicNumbers.length && viewStartsWith(view, format.magicNumbers)) {
            return format;
        }
    }

    return null;
}

/**
 * Adds an file input element to the DOM and calls click() on it.
 *
 * Eventlisteners for onchange and onerror are connected to that element and will resolve the function with null or the elements FileList
 *
 * default accepts only PNG and JPEG
 *
 * Support:
 * - filetype restriction is not supported in Edge
 * - restriction 'image/*' is used by default here because image/png etc. is not supported in iOS-Safari
 * - multiple parameter is ignored in Android < 4.0
 * @param multiple allows selection of multiple files (default false)
 */
export function getImageFileList(multiple: boolean = false, accept: string = '.png, .jpg, .jpeg'): Promise<FileList | null> {
    return getFileList(accept, multiple);
}

export function canvasToBlob(
    canvas?: HTMLCanvasElement & {msToBlob?: (() => Blob | null) | null},
    mimetype: string = ImageFormat.PNG.mimetype
): Promise<Blob | null> {
    if (canvas == null || !isImageMimetype(mimetype)) {
        return Promise.resolve(null);
    }

    if (
        canvas.toBlob != null &&
        // toBlob in safari only supports png and jpeg
        (!safari || [ImageFormat.PNG.mimetype, ImageFormat.JPG.mimetype].includes(mimetype))
    ) {
        return new Promise<Blob | null>((resolve: (returnValue: Blob | null) => void) => { canvas.toBlob(resolve, mimetype); });
    }

    if (canvas.msToBlob != null && mimetype === ImageFormat.PNG.mimetype) { // Note: msToBlob can only generate png images
        return Promise.resolve(canvas.msToBlob());
    }

    const dataUrl: string =  canvas.toDataURL(mimetype);
    const buffer: ArrayBuffer = fromDataUri(dataUrl);
    return Promise.resolve(new Blob([buffer], { type: mimetype}));
}

export function canvasToDataUrl(
    canvas?: HTMLCanvasElement,
    mimetype: string = ImageFormat.PNG.mimetype
): string | null {
    if (canvas == null || !isImageMimetype(mimetype)) {
        return null;
    }
    return canvas.toDataURL(mimetype);
}

export function drawImage(
    imageElement: HTMLImageElement,
    width: number,
    height: number,
    background?: string,
    canvasWidth?: number,
    canvasHeight?: number
): HTMLCanvasElement | undefined {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = canvasWidth ?? width;
    canvas.height = canvasHeight ?? height;

    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

    if (ctx != null) {
        if (isNotNullOrEmpty(background)) {
            ctx.fillStyle = background;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        }
        ctx.drawImage(
            imageElement,
            (canvas.width - width) / 2,
            (canvas.height - height) / 2,
            width,
            height
        );

        return canvas;
    }

    return;
}

export function getCenterPosition(src: ImageSize, target: ImageSize): ImagePosition {
    // FixMe: cases where the target property is lesser than the src property should be handled
    assert(target.width >= src.width, 'the width of the target should be greater than or equal to the with of the src');
    assert(target.height >= src.height, 'the height of the target should be greater than or equal to the height of the src');

    return {
        x: Math.floor((target.width - src.width) / 2),
        y: Math.floor((target.height - src.height) / 2)
    };
}

export async function resizedDataUrl(dataUrl: string, scale: number): Promise<string | null> {
    return new Promise<string | null>((resolve) => {
        if (scale === 1) {
            return resolve(dataUrl);
        }
        const imageElement: HTMLImageElement = new Image();
        imageElement.onload = () => {
            const canvas: HTMLCanvasElement = document.createElement('canvas');
            canvas.width = imageElement.width * scale;
            canvas.height = imageElement.height * scale;
            const context: CanvasRenderingContext2D = canvas.getContext('2d')!;
            context.scale(scale, scale);
            context.drawImage(imageElement, 0, 0);
            resolve(canvas.toDataURL());
        };
        imageElement.onerror = () => { resolve(null); };
        imageElement.src = dataUrl;
    });
}

interface ImageUrlToCanvasOptions {
    skipCorsProxy?: boolean;
    transparent?: boolean;
    width?: number;
    height?: number;
}

/**
 * @param url
 * @param options `{ addWhiteBackgound: boolean; useCorsProxy: boolean; width?: number; height?: number; }`
 *
 * @returns HTMLCanvasElement | undefined
 */
export async function imageUrlToCanvas(
    url: string | null | undefined,
    options: ImageUrlToCanvasOptions = {},
): Promise<HTMLCanvasElement | undefined> {
    if (isNullOrEmpty(url)) {
        return;
    }

    const { skipCorsProxy, transparent, ...maxCanvasSize } = options;

    const imageElement = await loadImage(url, !skipCorsProxy);

    if (imageElement == null) {
        return;
    }

    const canvas: HTMLCanvasElement = document.createElement('canvas');

    canvas.width = imageElement.width;
    canvas.height = imageElement.height;

    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

    if (ctx != null) {
        if (!transparent) {
            ctx.fillStyle = 'white';
        }

        ctx.fillRect(0, 0, canvas.width, canvas.height);
        const canvasScaleRatio: number = (maxCanvasSize.height != null || maxCanvasSize.width != null) ?
            getScaledImageRatio(imageElement, maxCanvasSize) : 1;

        const canvasSize: ImageSize = canvasScaleRatio < 1 ? scaleImageSize(imageElement, canvasScaleRatio) : imageElement;

        canvas.width = canvasSize.width;
        canvas.height = canvasSize.height;

        ctx.drawImage(imageElement, 0, 0, canvas.width, canvas.height);
    }

    return canvas;
}

export async function getDataUrlFromImageFile(
    imageFile: File,
    options: ImageUrlToCanvasOptions
): Promise<string | undefined> {
    if (options.width == null && options.height == null) {
        return new Promise<string | undefined>((resolve) => {
            const fr: FileReader = new FileReader();
            fr.onerror = () => {
                resolve(undefined);
            };
            fr.onload = () => {
                resolve(fr.result as string | undefined);
            };
            fr.readAsDataURL(imageFile);
        });
    }

    if (imageFile == null) {
        return;
    }

    const url: string = URL.createObjectURL(imageFile);

    try {
        const canvas: HTMLCanvasElement | undefined = await imageUrlToCanvas(url, options);
        if (canvas == null) {
            return;
        }
        return canvas.toDataURL(imageFile.type, 0.9);
    } finally {
        URL.revokeObjectURL(url);
    }
}

const MaxImageWidth: number = 2024;
const MaxImageHeight: number = 2024;
const MaxImageSize: Readonly<ImageSize> = {
    width: MaxImageWidth,
    height: MaxImageHeight
};

/** returns a scaled down version of the specified image dimension if required */
export function getImageDimension(size: ImageSize): ImageSize {
    const ratio: number = getScaledImageRatio(size, MaxImageSize);
    if (ratio >= 1) {
        return size;
    }

    return scaleImageSize(size, ratio);
}

export function getImageSrc(src: string, size: ImageSize): [string, ImageSize] {
    const ratio: number = getScaledImageRatio(size, MaxImageSize);
    if (ratio >= 1) {
        return [src, size];
    }

    return [
        getScaledImageUrl(MaxImageWidth, MaxImageHeight, src),
        scaleImageSize(size, ratio)
    ];
}
import i18next from 'i18next';
import { useCallback } from 'react';

import { snapshotDialogStore } from '../../../stores/SnapshotDialogStore';
import { viewerStore } from '../../../stores/ViewerStore';
import { getHTMLElement_ViewerContainer } from '../../../utils/ViewerUtilities';

export const useSnapshot = () => {
    return useCallback(async () => {
        const viewerVisible = viewerStore.viewerVisible();
        const viewerContainer = getHTMLElement_ViewerContainer();

        if (!viewerContainer || !viewerVisible) {
            return;
        }

        const domrect = viewerContainer.getBoundingClientRect();
        if (typeof domrect.width === 'number' && typeof domrect.height === 'number') {
            const viewerUtilities = viewerStore.viewerUtility();
            const blob = await viewerUtilities?.createScreenshot(domrect.width, domrect.height);

            if (blob) {
                snapshotDialogStore.open(i18next.t('Snapshot'), blob);
            } else {
                console.warn('blob is null');
            }
        }
    }, []);
};

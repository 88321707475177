import type { StyleRules, MakeStylesHook } from '@egr/xbox/utils/tss-react';
import type { TypographyProps } from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { makeStyles, getKeyframes } from '@egr/xbox/utils/tss-react';

type PlaceholderBarClasses = 'text' | 'icon' | 'greyBar' | 'inDarkContainer' | 'longText' | 'hiddenText';

function getPlaceholderBar(theme: Theme, props: unknown, classes: Record<PlaceholderBarClasses, string>): StyleRules<{}, PlaceholderBarClasses> {
    return {
        text: {
            width: '170px',
            maxWidth: '100%',
            position: 'relative'
        },
        longText: {
            width: 'calc(100% - 36px)',
        },
        greyBar: {
            backgroundColor: theme.palette.grey[300],
            borderRadius: 7,
            left: 0,
            lineHeight: '1 !important',
            position: 'absolute',
            top: '50%',
            width: '100%',
            transform: 'translateY(-50%)',
            [`.${classes.inDarkContainer} &`]: {
                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[500] : theme.palette.grey[300],
            }
        },
        inDarkContainer: { /* */ },
        icon: {
            backgroundColor: theme.palette.grey[300],
            borderRadius: theme.shape.borderRadius,
        },
        hiddenText: {
            opacity: 0,
        }
    };
}

export const useBlinkStyles: MakeStylesHook<'root'> = makeStyles({ name: 'Blink' })({
    root: {
        animation: getKeyframes('0% { opacity: 1; } 50% { opacity: .25; } 100% { opacity: 1; }', '2s infinite'),
    },
});

const usePlaceholderBarStyles: MakeStylesHook<PlaceholderBarClasses> =
    makeStyles<void, PlaceholderBarClasses>({ name: 'PlaceholderBar' })(getPlaceholderBar);

export const BlinkingGreyBar: React.FC<React.PropsWithChildren> = (props) => {
    const { classes, cx } = usePlaceholderBarStyles();
    const { classes: blinkClasses } = useBlinkStyles();

    return (
        <React.Fragment>
            <span className={classes.hiddenText}>{props.children}</span>
            <span className={cx(blinkClasses.root, classes.greyBar)}>&nbsp;</span>
        </React.Fragment>
    );
};

interface PlaceholderBarProps extends React.HTMLAttributes<HTMLDivElement> {
    show?: boolean;
    variant?: TypographyProps['variant'];
    inDarkContainer?: boolean;
    long?: boolean;
    disableBlink?: boolean;
}

export const PlaceholderBar: React.FC<PlaceholderBarProps> = observer(
    ({ variant = 'body1', ...props }) => {
        const { classes, cx } = usePlaceholderBarStyles();
        const { classes: blinkClasses } = useBlinkStyles();

        if (props.show) {
            return (
                <div
                    className={cx(
                        classes.text,
                        props.disableBlink ? undefined : blinkClasses.root,
                        props.className,
                        props.inDarkContainer ? classes.inDarkContainer : undefined,
                        props.long ? classes.longText : undefined
                    )}
                    style={props.style}
                >
                    <Typography variant={variant}>&nbsp;</Typography>
                    <Typography variant={variant} className={classes.greyBar}>&nbsp;</Typography>
                </div>
            );
        }

        return <React.Fragment>{props.children}</React.Fragment>;
    }
);

interface PlaceholderIconProps {
    className?: string;
    height?: React.CSSProperties['height'];
    show?: boolean;
    width?: React.CSSProperties['width'];
    style?: React.HTMLProps<'div'>['style'];
    disableBlink?: boolean;
}

export const PlaceholderIcon: React.FC<PlaceholderIconProps> = (props: React.PropsWithChildren<PlaceholderIconProps>) => {
    const { classes, cx } = usePlaceholderBarStyles();
    const { classes: blinkClasses } = useBlinkStyles();

    const style: React.CSSProperties | undefined = {
        ...(props.className == null ? {width: props.width ?? 23, height: props.height ?? 23} : undefined),
        ...props.style
    };

    if (props.show) {
        return (
            <div
                className={cx(
                    classes.icon,
                    props.disableBlink ? undefined : blinkClasses.root,
                    props.className
                )}
                style={style}
            />
        );
    }

    return <React.Fragment>{props.children}</React.Fragment>;
};
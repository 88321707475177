import type { ViewProps } from '../Views/View';

import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { debugTap, showDebugInfo } from '../Helper/Debug';
import { ToolbarLabel } from '../Toolbar/ToolbarLabel';
import { TopToolbar } from '../Toolbar/TopToolbar';
import { PlaceholderSubtitle } from '../ViewPlaceholder/PlaceholderSubtitle';
import { PlaceholderTitle } from '../ViewPlaceholder/PlaceholderTitle';
import { ViewPlaceholder } from '../ViewPlaceholder/ViewPlaceholder';
import View from '../Views/View';
import { ViewRoot } from '../Views/ViewRoot';
import ImageSrc from '../image-res/oops-error-grey.svg';

import { ImageWrapper } from './ImageWrapper';

import { AppName } from '@egr/xbox/utils/Constants';
import { captureException } from '@egr/xbox/utils/errors/Error';

type ErrorBoundaryProps = React.PropsWithChildren<{
    silent: boolean;
    color?: ViewProps['color'];
}>;

const StyledImageWrapper = styled(ImageWrapper)({
    width: '100%',
    height: 345,
    marginBottom: -16,
    marginTop: -20,
});

interface ErrorContextProps {
    error: Error | null | undefined;
    errorInfo: React.ErrorInfo | null | undefined;
}

const ErrorContext = React.createContext<ErrorContextProps>({
    error: null,
    errorInfo: null
});

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorContextProps> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null
        };
    }

    public override componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        this.setState({
            error,
            errorInfo
        });

        captureException(
            error,
            'fatal',
            {
                extra: {
                    errorInfo: errorInfo
                }
            }
        );
    }

    public override render(): React.ReactNode {
        if (this.state.error != null) {
            if (this.props.silent === true) {
                return null;
            }

            return (
                <ErrorContext.Provider value={{...this.state}}>
                    <ErrorView color={this.props.color}/>;
                </ErrorContext.Provider>
            );
        }

        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    }
}

interface ErrorViewProps {
    color?: ViewProps['color'];
}

const ErrorView: React.FC<ErrorViewProps> = (props) => {
    const { t } = useTranslation();

    return (
        <ViewRoot>
            <View
                visible={true}
                color={props.color ?? 'primary'}
            >
                <ViewPlaceholder>
                    <AppBar color="default" elevation={0}>
                        <TopToolbar>
                            <ToolbarLabel>{AppName}</ToolbarLabel>
                        </TopToolbar>
                    </AppBar>
                    <StyledImageWrapper src={ImageSrc}/>
                    <PlaceholderTitle onClick={debugTap}>{t('Oops, an error occurred')}</PlaceholderTitle>
                    <PlaceholderSubtitle onClick={debugTap}>{t('Please restart the app.')}</PlaceholderSubtitle>
                    <ErrorDetails/>
                </ViewPlaceholder>
            </View>
        </ViewRoot>
    );
};

const ErrorDetails = observer(() => {
    const { error, errorInfo } = React.useContext(ErrorContext);

    if (!showDebugInfo.get() || error == null) {
        return null;
    }

    return (
        <React.Fragment>
            <pre>{error.name}</pre>
            <pre>{error.message}</pre>
            <pre>{error.stack}</pre>
            {errorInfo != null ? <pre>{errorInfo.componentStack}</pre> : null}
        </React.Fragment>
    );
});
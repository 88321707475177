import type { MakeStylesHook, StyleRules } from '@egr/xbox/utils/tss-react';

import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';

import { makeStyles } from '@egr/xbox/utils/tss-react';

interface IdataEvent {
    title: string;
    html: string;
    tdWidth: string;
}

const customEventId: string = 'customEventId_DialogMessage';

type Classes = 'dialog' | 'close';
function getStyles(): StyleRules<Classes> {
    return (
        {
            dialog: {
                width: '90%',
                height: '90%',
                border: 'none',
                borderRadius: '3px',
                // userSelect: 'none',
                overflow: 'hidden',
                margin: 'auto',
                // display: 'block', // remove it!
                boxSizing: 'border-box',
                padding: '6%',
            },
            close: {
                position: 'absolute',
                width: '24px',
                height: '24px',
                top: '1.5%',
                right: '1.5%',
                cursor: 'pointer'
            }
        }
    );
}

const useStyles: MakeStylesHook<Classes> = makeStyles({ name: 'DialogImprint' })(getStyles);

const refDialog: React.RefObject<HTMLDialogElement> = React.createRef<HTMLDialogElement>();
const refContent: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
export const DialogImprint: React.FC = () => {
    const { classes }= useStyles();

    const defaultData: IdataEvent = { title: '', html: '', tdWidth: '50%' };

    const [data, setData] = React.useState(defaultData);
    React.useEffect(
        () => {
            const listener: (e: Event) => void = (e: Event) => {
                const ce: CustomEvent<IdataEvent> = e as CustomEvent<IdataEvent>;

                setData(ce.detail);
                if (refDialog?.current) {
                    const dialog: HTMLDialogElement = refDialog.current;
                    dialog.showModal();
                    // dialog.innerHTML = ce.detail.html;
                    dialog.style.display = 'block';
                    if (refContent?.current) {
                        const content: HTMLDivElement = refContent.current;
                        content.innerHTML = ce.detail.html;
                    }
                }
            };
            document.addEventListener(customEventId, listener);
            return () => { document.removeEventListener(customEventId, listener); };
        }
        ,
        [] // runs only once
    );
    if (!data.html) {
        return <div />;
    }
    function close() {
        refDialog.current?.close();
        setData({ title: '', html: '', tdWidth: '50%' });
    }

    return (
        <dialog ref={refDialog} className={classes.dialog} style={{ position: 'relative' }}>
            <style>
                {'dialog::backdrop {background-color: rgba(0, 0, 0, 0.6);}'}
            </style>
            <CloseIcon
                style={{ fontSize: 18 }}
                className={classes.close}
                onClick={close}
            />
            <style>
                {`
                .classlegal { font-size: 13px;}
                .classlegal tr{}
                .classlegal td{
                    display: table-cell;                    
                    width: ` + data.tdWidth + `; 
                    box-sizing: border-box;
                    vertical-align: top; 
                    padding: 0px;
                    padding-right: 40px;
                }
                @media screen and (max-width: 1000px) {
                    .classlegal td {
                        display: block;
                        width: 90%
                    }
                }               
                .main a {color: #f7a600;text-decoration: none; font-size: 13px !important;}
                .main a:after { content: "" !important;}
                `}
            </style>
            <div ref={refContent} className="classlegal" style={{ width: '100%', height: '100%', overflow: 'auto' }} />
        </dialog>
    );
};
export function dispatchCustomEvent_DialogMessage(data: IdataEvent) {
    document.dispatchEvent(
        new CustomEvent<IdataEvent>(
            customEventId,
            { detail: data }
        )
    );
}
import type * as React from 'react';

interface FooterLinkProps {
    href: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    children: React.ReactNode;
    isLast?: boolean;
}

const FooterLink: React.FC<FooterLinkProps> = ({ href, onClick, children, isLast }) => (
    <li style={{ display: 'inline' }}>
        <a
            href={href}
            onClick={onClick}
            target={href.startsWith('http') ? '_blank' : undefined}
            rel={href.startsWith('http') ? 'noreferrer' : undefined}
        >
            {children}
        </a>
        {!isLast && <span> | </span>}
    </li>
);

export default FooterLink;

import type { EdgeInsets } from '@egr/xbox/app-api/WebAction';
import type { CSSObject } from 'tss-react/types';

import { makeObservable, observable, runInAction } from 'mobx';

import { isAndroidApp } from '@egr/xbox/app-api/AppDetection';
import { appInfo } from '@egr/xbox/app-api/AppInfo';
import { isNullOrZero } from '@egr/xbox/utils/Number';
import { getEnvVar } from '@egr/xbox/utils/ReactScriptHelper';
import { mediaQueryMatch } from '@egr/xbox/utils/UserAgent';

export enum ScreenshotModeDevice {
    'IPHONE_LG',
    'IPHONE_SM',
    'IPAD_2G',
    'IPAD_3G',
    'ANDROID_TABLET',
    'ANDROID_PHONE',
    'NO_DEVICE'
}

export const screenshotModeDevice: ScreenshotModeDevice | undefined = ScreenshotModeDevice[getEnvVar('SCREENSHOT_MODE_DEVICE')!];

/* iphone X */
const MediaQueryIphoneX: string = '@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)';
const MediaQueryIphoneXR: string = '@media only screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 2)';
const MediaQueryIphoneXS: string = '@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3)';
const MediaQueryIphoneXSMax: string = '@media only screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 3)';

export function iPhoneXQueries(cssProperties: CSSObject): CSSObject {
    return {
        [MediaQueryIphoneX]: cssProperties,
        [MediaQueryIphoneXR]: cssProperties,
        [MediaQueryIphoneXS]: cssProperties,
        [MediaQueryIphoneXSMax]: cssProperties,
    };
}

export function isIphoneXPortrait(): boolean {
    return (
        mediaQueryMatch(MediaQueryIphoneX.replace('@media ', '') + ' and (orientation: portrait)') ||
        mediaQueryMatch(MediaQueryIphoneXR.replace('@media ', '') + ' and (orientation: portrait)') ||
        mediaQueryMatch(MediaQueryIphoneXS.replace('@media ', '') + ' and (orientation: portrait)') ||
        mediaQueryMatch(MediaQueryIphoneXSMax.replace('@media ', '') + ' and (orientation: portrait)')
    );
}

type SafeAreaDirection = 'top' | 'right' | 'bottom' | 'left';

class SafeAreaVariables {
    public values = new Map<SafeAreaDirection, string>();

    @observable
    public statusbarHeight: number = 0;

    private testDiv: HTMLDivElement = document.createElement('div');

    constructor() {
        makeObservable(this);
        this.get = this.get.bind(this);
        this.set = this.set.bind(this);

        this.testDiv.style.height = 'var(--safeareatop)';
        this.testDiv.style.position = 'absolute';
        this.testDiv.style.visibility = 'none';
        this.testDiv.style.pointerEvents = 'none';
        document.body.appendChild(this.testDiv);

        this.set('right', 'env(safe-area-inset-right)');
        this.set('left', 'env(safe-area-inset-left)');

        if (screenshotModeDevice) {
            this.set('bottom', `${getFakeBottomPadding()}px`);
            this.set('top', `${getFakeStatusbarPadding()}px`);
        } else {
            this.set('bottom', 'env(safe-area-inset-bottom)');

            if (isAndroidApp) {
                this.set('top', `${isNullOrZero(appInfo?.status_bar_height) ? 44 : appInfo.status_bar_height}px`);
            } else {
                this.set('top', 'env(safe-area-inset-top)');
            }
        }

    }

    public set(direction: SafeAreaDirection, value: string | undefined): void {
        if (value != null) {
            if (this.values.get(direction) !== value) {
                this.values.set(direction, value);
            }
            document.documentElement.style.setProperty(`--safearea${direction}`, value);
            if (direction === 'top') {
                runInAction(() => {
                    this.statusbarHeight = this.testDiv.getBoundingClientRect().height;
                });
            }
        }
    }

    public get(direction: SafeAreaDirection): string | undefined {
        return this.values.get(direction);
    }
}

export const SafeAreaEnvMap = new SafeAreaVariables();

/**
 * Should be used for Android phones without notch,
 * because there env(safe-inset-area-top) is 0 and not as expected the height of the statusbar
 * @param height
 */
export function setFakeStatusBarHeight(height?: number): void {
    if (screenshotModeDevice != null && height == null) {
        SafeAreaEnvMap.set('top', `${getFakeStatusbarPadding()}px`);
        SafeAreaEnvMap.set('bottom', `${getFakeBottomPadding()}px`);
    }
    if (height != null) {
        SafeAreaEnvMap.set('top', `${height}px`);
    }
}

export function setInsets(insets: EdgeInsets): void {
    if (screenshotModeDevice != null) {
        return;
    }
    SafeAreaEnvMap.set('top', `${insets.top}px`);
    SafeAreaEnvMap.set('bottom', `${insets.bottom}px`);
    SafeAreaEnvMap.set('left', `${insets.left}px`);
    SafeAreaEnvMap.set('right', `${insets.right}px`);
}

/**
 * Helper function to add notch-size values
 * @param property
 * @param direction
 */
export function safeArea(
    property: keyof CSSObject,
    direction: SafeAreaDirection,
    defaultValue: number = 0,
): CSSObject {
    return { [property]: `calc(${defaultValue}px + var(--safearea${direction}))` };
}

export const WhiteSpacePre: CSSObject = {whiteSpace: 'pre'};

function getFakeStatusbarPadding(): number {
    switch (screenshotModeDevice) {
        case ScreenshotModeDevice.IPHONE_LG:
            // height given from app should be 44px but is 48px
            return 48;
        case ScreenshotModeDevice.IPHONE_SM:
        case ScreenshotModeDevice.IPAD_2G:
        case ScreenshotModeDevice.IPAD_3G:
            return 20;
        case ScreenshotModeDevice.ANDROID_PHONE:
            // Pixel 5
            return 49;
        default:
            return 0;
    }
}

function getFakeBottomPadding(): number {
    switch (screenshotModeDevice) {
        case ScreenshotModeDevice.IPHONE_LG:
            // height given from app should be 44px but is 48px
            return 48;
        case ScreenshotModeDevice.IPHONE_SM:
        case ScreenshotModeDevice.IPAD_2G:
        case ScreenshotModeDevice.IPAD_3G:
        case ScreenshotModeDevice.ANDROID_PHONE:
        default:
            return 0;
    }
}
import Button from '@mui/material/Button';
import * as React from 'react';

interface AppBarButtonProps {
    disabled: boolean,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    label?: string,
    icon: React.ReactNode
}

export const AppBarButton: React.FC<AppBarButtonProps & React.RefAttributes<HTMLButtonElement | null>> =
    React.forwardRef((props, ref) => {
    return (
        <Button
            ref={ref}
            sx={{
                lineHeight: '1.2em',
                fontWeight: 600,
                color: '#FFFFFF',
                '&.Mui-disabled': {
                    color: 'rgba(255, 255, 255, 0.3)'
                }
            }}
            onClick={props.onClick}
            disabled={props.disabled}
            data-sentrylabel={`appbar-${props.label}`}
        >
            {props.icon}
            {props.label}
        </Button>
    );
});
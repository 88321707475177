import Typography from '@mui/material/Typography';
import * as React from 'react';

import { viewerStore } from '../../stores/ViewerStore';

import { safeArea } from '@egr/xbox/egr-gui-elements/Helper/StyleHelper';
import { makeStyles } from '@egr/xbox/utils/tss-react';

const useEvaluationOverlayStyles = makeStyles({ name: 'EvaluationOverlay' })((theme) => ({
    root: {
        zIndex: 9999,
        paddingLeft: 4,
        paddingRight: 4,
        fontSize: 10,
        borderBottomRightRadius: 4,
        background: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
    error: {
        background: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        ...safeArea('top', 'top')
    },
    hint: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        ...safeArea('bottom', 'bottom')
    }
}));

export const ExperimentalOverlay: React.FC = () => {
    const { classes, cx } = useEvaluationOverlayStyles();
    const { experimental } = viewerStore.state;
    if (!experimental) {
        return;
    }

    return (
        <React.Fragment>
            <Typography className={cx(classes.root, classes.error)}>Experimental feature</Typography>
        </React.Fragment>
    );
};
import type { IObservableValue} from 'mobx';

import { observable, runInAction } from 'mobx';

export const showDebugInfo: IObservableValue<boolean> = observable.box<boolean>(false);

export function enableDebugInfo(): void {
    runInAction((): void => {
        showDebugInfo.set(true);
        document.body.style.userSelect = 'auto';
    });
}

export function getDebugTapCallback(callback: VoidFunction): VoidFunction {
    let tabs: number = 0;

    return (): void => {
        if (++tabs === 7) {
            callback();
        }

        window.setTimeout((): void => { tabs--; }, 5000);
    };
}

export const debugTap: VoidFunction = getDebugTapCallback(enableDebugInfo);
import type * as React from 'react';

import Close from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';

import { dialogManager } from '../../stores/DialogManager';

import { ToolbarLabel } from '@egr/xbox/egr-gui-elements/Toolbar/ToolbarLabel';
import { TopToolbar } from '@egr/xbox/egr-gui-elements/Toolbar/TopToolbar';
import { FlexSpacer } from '@egr/xbox/egr-gui-elements/single-components/FlexSpacer';

export const ModalIframeBar: React.FC = () => {
        if (dialogManager.state.iframeBarStyle === 'hidden') {
            return null;
        }

        const close: () => void = () => {
            dialogManager.hideIframe();
        };

        if (dialogManager.state.iframeBarStyle === 'justClose') {
            return (
                <AppBar
                    color="default"
                    elevation={0}
                    position="relative"
                    style={{borderColor: 'transparent'}}
                >
                    <TopToolbar>
                        <FlexSpacer/>
                        <IconButton data-sentrylabel="appBar-closeIcon" onClick={close} edge="end" size="large">
                            <Close data-sentrylabel="appBar-closeIcon"/>
                        </IconButton>
                    </TopToolbar>
                </AppBar>
            );
        }

        return (
            <AppBar
                color="default"
                elevation={0}
                position="relative"
            >
                <TopToolbar>
                    <IconButton data-sentrylabel="appBar-closeIcon" onClick={close} edge="start" size="large">
                        <Close data-sentrylabel="appBar-closeIcon"/>
                    </IconButton>
                    <ToolbarLabel>
                        {dialogManager.state.iframeTitle}
                    </ToolbarLabel>
                </TopToolbar>
            </AppBar>
        );
    };
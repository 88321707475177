import * as React from 'react';

export interface OverrideableAppThemeContextProps {
    disableMuiTooltips: boolean;
    disableLazyLoadImage: boolean;
    useBottomSheet: boolean;
    hideLogoInArticleList: boolean;
    greyGDIcons: boolean;
    thinIcons: boolean;
    dialogVariant: 'default' | 'newDesign';
}

export interface AppThemeContextProps extends OverrideableAppThemeContextProps {
    smallLayout: boolean;
    phoneLandscape: boolean;
    darkmode: boolean;
    imagePlaceholder: React.ReactNode;
    loadingImagePlaceholder: React.ReactNode;
    productPlaceholder: React.ReactNode;
    projectPlaceholder: React.ReactNode;
}

export const AppThemeContextDefault: AppThemeContextProps = {
    disableMuiTooltips: false,
    disableLazyLoadImage: false,
    smallLayout: false,
    phoneLandscape: false,
    darkmode: false,
    imagePlaceholder: null,
    loadingImagePlaceholder: null,
    productPlaceholder: null,
    projectPlaceholder: null,
    useBottomSheet: false,
    hideLogoInArticleList: false,
    dialogVariant: 'default',
    greyGDIcons: false,
    thinIcons: false,
};

export const AppThemeContext: React.Context<AppThemeContextProps> =
    React.createContext<AppThemeContextProps>(AppThemeContextDefault);

export interface AppThemeComponents {
    imagePlaceholder?: React.ReactNode;
    loadingImagePlaceholder?: React.ReactNode;
    productPlaceholder?: React.ReactNode;
    projectPlaceholder?: React.ReactNode;
}
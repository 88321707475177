import i18next from 'i18next';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import  { appStore } from './stores/AppStore';
import './styles/Index.css';
import { getHTMLElement_Root } from './utils/ViewerUtilities';

import { initInstance } from '@egr/xbox/base-app/i18n';
import { getEnvVar } from '@egr/xbox/utils/ReactScriptHelper';

getEnvVar('BACKEND_URL');
export function startApp(): void {
    void (async () => {
        // await getUrlInfo(url, 'index before all...');
        await appStore.fetchUrlInfo();

        await initInstance(undefined, undefined);

        appStore.setCurrentLanguage(i18next.languages[0]);

        const root = createRoot(getHTMLElement_Root()!);
        root.render(
            <React.StrictMode>
                <App />
            </React.StrictMode>
        );
    })();
}
function WebGLRenderingContextDetected() {
    // https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API/By_example/Detect_WebGL
    // Create canvas element. The canvas is not added to the
    // document itself, so it is never displayed in the
    // browser window.
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    // Get WebGLRenderingContext from canvas element.
    const gl: WebGLRenderingContext | RenderingContext | null = canvas.getContext('webgl') ?? canvas.getContext('experimental-webgl');

    return gl instanceof WebGLRenderingContext;
}

window.addEventListener('load', (event) => {
    if (WebGLRenderingContextDetected()) {
        startApp();
    } else {
        console.warn('WebGLRenderingContext not detected'); // might be on a server environment
    }
});

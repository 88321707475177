import type { FileExtension } from '../utils/UrlInfo';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MemoryRouter, Routes, Route } from 'react-router-dom';

import { ExperimentalOverlay } from '../components/debug/ExperimentalOverlay';
import { appStore } from '../stores/AppStore';
import { TelemetryTrackEvent } from '../utils/Telemetry';
import { isEgrFileService_AR_MultiFileLink } from '../utils/UrlInfo';

import { developmentMode, getEnvValue } from '@egr/xbox/utils/ReactScriptHelper';
import { Logger } from '@egr/xbox/utils/errors/Logger';

const LazyViewerXr = React.lazy(() => import('./ViewerXr'));
const LazyViewerModelViewer = React.lazy(() => import('./ViewerGlb/ViewerModelViewer'));
const LazyViewerBabylon = React.lazy(() => import('./ViewerBabylon/ViewerBabylon'));
const LazyViewerThree = React.lazy(() => import('./ViewerThree/ViewerThree'));
const LazyViewerMarzipano = React.lazy(() => import('./ViewerMarzipano/ViewerMarzipano'));
const LazyViewerKrpano = React.lazy(() => import('./ViewerKrpano'));
const LazyViewerAr = React.lazy(() => import('./ViewerAR'));
const LazyViewerUsd = React.lazy(() => import('./ViewerUsd/ViewerUSD'));

export const ViewerContainer: React.FC = () => {
    const [t] = useTranslation();
    const { embed, urlInfo } = appStore.state;

    const displayIt = React.useMemo(() => urlInfo?.extension !== '', [urlInfo]);

    const getInitialRoute = React.useMemo(() => {
        if (!urlInfo) return '';

        const fileExtension: FileExtension = urlInfo.extension;
        if (developmentMode) {
            Logger.log('fileExtension: ', fileExtension);
        }
        if (isEgrFileService_AR_MultiFileLink(location.href) || urlInfo.arFile) {
            // link with glb= and usdz= query parameters
            console.warn('isEgrFileService... await this.getARViewer()');
            TelemetryTrackEvent('Load content', 'glb_usdz', '1');
            return '/ar';
        }

        switch (fileExtension) {
            case 'jpg':
            case 'png':
                TelemetryTrackEvent('Load content', 'pano', '1');
                return '/krpano';
            case 'pec':
                TelemetryTrackEvent('Load content', 'pec', '1');
                return '/babylon';
            case 'eimg':
                TelemetryTrackEvent('Load content', 'eimg', '1');
                return '/marzipano';
            case 'epan':
                TelemetryTrackEvent('Load content', 'panoeimg', '1');
                return '/marzipano';
            case 'gltf':
            case 'glb':
                TelemetryTrackEvent('Load content', 'glb', '1');
                switch (getEnvValue('GLBLOADER', ['babylon', 'google', 'three'], 'babylon')) {
                    case 'babylon':
                        return '/babylon';
                    case 'google':
                        return '/modelviewer';
                    case 'three':
                        return '/three';
                    default:
                        return '/modelviewer';
                }
            case 'iar':
                TelemetryTrackEvent('Load content', fileExtension, '1');
                return '/ar';
            case 'usdz':
                TelemetryTrackEvent('Load content', fileExtension, '1');
                return '/usdz';
            default:
                return '/babylon';
        }
    }, [urlInfo]);

    if (!displayIt) {
        return null;
    }

    return (
        <div
            id="viewer-container"
            style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: 'white',
            }}
        >
            <React.Suspense>
                <MemoryRouter initialEntries={[getInitialRoute]}>
                    <Routes>
                        <Route path="/marzipano" element={<LazyViewerMarzipano />} />
                        <Route path="/xr" element={<LazyViewerXr />} />
                        <Route path="/modelviewer" element={<LazyViewerModelViewer />} />
                        <Route path="/krpano" element={<LazyViewerKrpano />} />
                        <Route path="/ar" element={<LazyViewerAr />} />
                        <Route path="/usdz" element={<LazyViewerUsd />} />
                        <Route path="/babylon" element={<LazyViewerBabylon />} />
                        <Route path="/three" element={<LazyViewerThree />} />
                    </Routes>
                </MemoryRouter>
            </React.Suspense>
            {embed && (
                <div id="watermark" className="watermark">
                    {t('powered by EasternGraphics')}
                </div>
            )}
            <ExperimentalOverlay />
        </div>
    );
};

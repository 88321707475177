import type { TypographyProps } from '@mui/material/Typography';
import type * as React from 'react';

import Typography from '@mui/material/Typography';

type PlaceholderSubtitleProps = Omit<TypographyProps, 'variant' | 'align' | 'style'>;

export const PlaceholderSubtitle: React.FC<PlaceholderSubtitleProps> = (props: PlaceholderSubtitleProps) => {
    return (
        <Typography {...props} variant="body2" align="center" style={{maxWidth: 380}}/>
    );
};
import type { StyleRules, MakeStylesHook } from '@egr/xbox/utils/tss-react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {snapshotDialogStore} from '../stores/SnapshotDialogStore';

import Dialog from '@egr/xbox/egr-gui-dialogs/utils/DialogOverride';
import { downloadBlob } from '@egr/xbox/utils/Download';
import { ios } from '@egr/xbox/utils/UserAgent';
import { makeStyles } from '@egr/xbox/utils/tss-react';

function styles(): StyleRules<'paper'> {
    return {
        paper: {
            maxWidth: 480
        }
    };
}
const useStyles: MakeStylesHook<'paper'> = makeStyles({ name: 'SnapshotDialog' })(styles);

interface FullscreenHTMLElement extends HTMLImageElement {
    mozRequestFullScreen?: () => void;
    msRequestFullscreen?: () => void;
    webkitRequestFullscreen?: () => void;
}

const SnapshotDialog: React.FC = () => {
        const { classes } = useStyles();
        const { t } = useTranslation();
        const {snapshot, show, title} = snapshotDialogStore.state;

        const fullscreenElement = useRef<FullscreenHTMLElement>(null);

        const fullscreen = async (): Promise<void> => {
            if (fullscreenElement.current) {
                if (fullscreenElement.current.requestFullscreen) {
                    await fullscreenElement.current.requestFullscreen();
                } else if (fullscreenElement.current.mozRequestFullScreen) {
                    fullscreenElement.current.mozRequestFullScreen();
                } else if (fullscreenElement.current.webkitRequestFullscreen) {
                    fullscreenElement.current.webkitRequestFullscreen();
                }
            }
        };

        const save = (): void => {
            downloadBlob(snapshot, 'impress.jpg');
        };

        let src: string = '';
        if (snapshot) {
            src = URL.createObjectURL(snapshot);
        }
        return (
            <Dialog
                classes={{ paper: classes?.paper }}
                open={show}
                onClose={() => snapshotDialogStore.close()}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <div style={{ position: 'relative' }}>
                        <div
                            style={{ display: 'inline-block', maxWidth: '100%', maxHeight: 300 }}
                        >
                            <img
                                src={src}
                                style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
                                ref={fullscreenElement}
                            />
                        </div>
                        {!ios ?
                            <img
                                src={'images/ic_fullscreen_black_24px.svg'}
                                style={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 10,
                                    cursor: 'pointer',
                                }}
                                onClick={fullscreen}
                            />
                            : null
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" style={{ fontWeight: 'bold' }} onClick={() => snapshotDialogStore.close()}>{t('Close')}</Button>
                    <Button color="primary" style={{ fontWeight: 'bold' }} onClick={save}>{t('Save')}</Button>
                </DialogActions>
            </Dialog>
        );
    };

export default SnapshotDialog;

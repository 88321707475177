import type { SafeArea } from '../../utils/ViewerUtilities';

import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import Button from '@mui/material/Button';

import { getSafeArea, isiPhone, isLandscape, isMobileDevice, isPhoneLandscape, isPortrait } from '../../utils/ViewerUtilities';

import { android, ios } from '@egr/xbox/utils/UserAgent';

const style: React.CSSProperties = {
    position: 'fixed',
    top: 75,
    left: 0,
    zIndex: 100,
};

const DeviceInfo: React.FC = () => {
    const handleClickSystemInfo = (): void => {
        const safeArea: SafeArea = getSafeArea();
        const windowOrientation: string | number = window.orientation;
        const screenOrientation: ScreenOrientation = screen.orientation;
        let info: string = 'Device info:\n';
        info += 'window.innerWidth/Height: ' + window.innerWidth + '/' + window.innerHeight + '\n';
        info += 'screen.Width/Height: ' + screen.width + '/' + screen.height + '\n';
        info += 'screen.availWidth/Height: ' + screen.availWidth + '/' + screen.availHeight + '\n';
        info += 'safeArea.top/right/bottom/left: ' + safeArea.top + '/' + safeArea.right + '/' + safeArea.bottom + '/' + safeArea.left + '/' + '\n';
        info += 'isMobileDevice()?' + isMobileDevice() + '\n';
        info += 'isMobileDevice_iOS()?' + ios + '\n';
        info += 'isiPhone?' + isiPhone() + '\n';
        info += 'isAndroidPhone?' + android + '\n';
        info += 'isPortrait?' + isPortrait() + '\n';
        info += 'isLandscape?' + isLandscape() + '\n';
        info += 'isPhoneLandscape?' + isPhoneLandscape() + '\n';
        info += 'windowOrientation:' + windowOrientation + '\n';
        info += 'screenOrientation?' + (screenOrientation?.angle) + '\n';
        info += 'getBoundingClientRect().top: ' + document.documentElement.getBoundingClientRect().top + '\n';
        alert(info);
    };

    return (
        <Button onClick={handleClickSystemInfo} style={style}>
            <PhonelinkSetupIcon style={{ color: '#868686' }} />
        </Button>
    );
};

export default DeviceInfo;

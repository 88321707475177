import type { MakeStylesHook, StyleRules } from '@egr/xbox/utils/tss-react';
import type { Theme} from '@mui/material/styles';
import type * as React from 'react';

import { alpha } from '@mui/material/styles';

import { makeStyles } from '@egr/xbox/utils/tss-react';

type ViewPlaceholderClassNames = 'root' | 'container' | 'primary';

function getViewPlaceholderStyles(theme: Theme): StyleRules<{}, ViewPlaceholderClassNames> {
    return ({
        root: {
            display: 'flex',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 0,
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingRight: theme.spacing(6),
            paddingLeft: theme.spacing(6),
            maxWidth: '100%'
        },
        primary: {
            backgroundColor: alpha(theme.palette.primary.main, .25)
        }
    });
}

const useViewPlaceholderStyles: MakeStylesHook<ViewPlaceholderClassNames> = makeStyles({ name: 'ViewPlaceholder' })(getViewPlaceholderStyles);

type ViewPlaceholderProps = React.PropsWithChildren<{
    color?: 'primary' | 'default';
}>;

export const ViewPlaceholder: React.FC<ViewPlaceholderProps> = (props) => {
    const { classes, cx } = useViewPlaceholderStyles();

    return (
        <div
            className={cx(
                classes.root,
                props.color === 'primary' ? classes.root : undefined
            )}
        >
            <div className={classes.container}>
                {props.children}
            </div>
        </div>
    );
};
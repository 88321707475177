import type * as React from 'react';

import logo from '../images/male.svg';
import { appStore } from '../stores/AppStore';
import { errorMessageStore } from '../stores/ErrorMessageStore';

import Wrapper from './Wrapper';

const ErrorMessage: React.FC = () => {
        const { show, error } = errorMessageStore.state;
        const { embed } = appStore.state;

        if (show) {
            return (
                <Wrapper className="error-message">
                    <img src={logo} style={{ maxWidth: '100%' }} />
                    <h1>{embed ? error?.embedMessage : error?.message}</h1>
                    <p>{embed ? error?.description : error?.description}</p>
                </Wrapper>
            );
        }
        return null;
    };

export default ErrorMessage;

import type { MakeStylesHook } from '@egr/xbox/utils/tss-react';
import type { ToolbarProps } from '@mui/material/Toolbar';

import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';

import { safeArea } from '../Helper/StyleHelper';

import { makeStyles } from '@egr/xbox/utils/tss-react';

export interface TopToolbarProps extends Omit<ToolbarProps, 'style'> {
    noMargin?: boolean;
}

type TopToolbarClasses = 'root' | 'safeArea' | 'withClick';

const useTopToolbarStyles: MakeStylesHook<TopToolbarClasses> = makeStyles({ name: 'TopToolbar' })({
    root: {
        minHeight: '56px !important',
    },
    safeArea: safeArea('marginTop', 'top'),
    withClick: {
        cursor: 'pointer'
    }
});

export const TopToolbar: React.FC<TopToolbarProps> = React.memo(
    (props: React.PropsWithChildren<TopToolbarProps>) => {
        const { classes, cx } = useTopToolbarStyles();

        const { noMargin, className, ...thisProps } = props;

        return (
            <Toolbar
                {...thisProps}
                className={cx(
                    className,
                    classes.root,
                    noMargin ? undefined : classes.safeArea,
                    props.onClick != null ? classes.withClick : undefined,
                )}
            />
        );
    }
);
import type { CookieUserSetting } from '@egr/xbox/egr-gui-dialogs/cookieDialog/CookieUserSettingManager';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { cookieStore } from '../../stores/CookiesVisibilityStore';
import { dialogManager } from '../../stores/DialogManager';
import { openLegalNotice, openTermsOfUse } from  '../footer/Footer';

import { CookieDialogText } from './CookieDialogText';
import { getAcceptedCookieUserSetting, saveAcceptedCookieUserSetting } from './CookieUserSettingManager';

import { FEATURE_YOUTUBE_WARNING } from '@egr/xbox/egr-gui-dialogs/Constants';
import Dialog from '@egr/xbox/egr-gui-dialogs/utils/DialogOverride';
import { Hide } from '@egr/xbox/egr-gui-elements/single-components/Hide';
import { LinkLikeText } from '@egr/xbox/egr-gui-elements/single-components/LinkLikeText';
import { matomoActive } from '@egr/xbox/telemetry/MatomoUtils';
import { telemetryPossible } from '@egr/xbox/telemetry/TrackingConstants';
import { makeStyles } from '@egr/xbox/utils/tss-react';

const useCookieDialogStyles = makeStyles({ name: 'CookieDialog' })((theme) => ({
    background: {
        backgroundColor: theme.palette.background.default,
    },
    label: {
        marginRight: theme.spacing(3),
    },
    actions: {
        padding: '8px 24px 24px 16px',
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            display: 'block',
            marginLeft: 8,
        },
        '& > *:first-child': {
            marginRight: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            '& > *:first-child': {
                marginBottom: 16,
            },
            '& > *': {
                width: '100%',
            },
            '& > *:not(:first-child)': {
                marginTop: 8
            },
        }
    },
    formGroup: {
        marginTop: 21,
        marginBottom: 9
    }
}));

export const CookieDialog: React.FC = () => {
        const { classes } = useCookieDialogStyles();
        const { t } = useTranslation();
        const { visibility } = cookieStore.state;

        const userSetting: CookieUserSetting = getAcceptedCookieUserSetting();
        const [telemetry, setTelemetry] = React.useState<boolean>(userSetting.telemetry);
        const [diagnostics, setDiagnostics] = React.useState<boolean>(userSetting.diagnostics);
        const [youtube, setYoutube] = React.useState<boolean>(userSetting.youtube);

        function onDiagnostics(_event: unknown, checked: boolean) {
            setDiagnostics(checked);
        }

        function onYoutube(_event: unknown, checked: boolean) {
            setYoutube(checked);
        }

        function onTelemetry(_event: unknown, checked: boolean) {
            setTelemetry(checked);
        }

        const selectAll: () => void = React.useCallback(
            () => {
                setTelemetry(true);
                setDiagnostics(true);
                setYoutube(FEATURE_YOUTUBE_WARNING);
                dialogManager.state.resolveCookieDialog?.({
                    required: true,
                    telemetry: true,
                    youtube: FEATURE_YOUTUBE_WARNING,
                    diagnostics: true
                });
                // saves cookies with the accepted settings
                saveAcceptedCookieUserSetting({
                    required: true,
                    telemetry: true,
                    youtube: FEATURE_YOUTUBE_WARNING,
                    diagnostics: true
                });
                dialogManager.closeCookieDialog();
            },
            []
        );

        function apply() {
                dialogManager.state.resolveCookieDialog?.({
                    required: true,
                    telemetry,
                    youtube,
                    diagnostics
                });
                saveAcceptedCookieUserSetting({
                    required: true,
                    telemetry,
                    youtube,
                    diagnostics
                });
                dialogManager.closeCookieDialog();
        }

        return (
            <Dialog
                open={visibility}
                className={classes.background}
            >
                <DialogTitle>{t('Cookie settings')}</DialogTitle>
                <DialogContent>
                    <CookieDialogText onLinkClick={openTermsOfUse} data-sentrylabel="cookie-impressum"/>
                    <FormGroup row={true} className={classes.formGroup}>
                        <FormControlLabel
                            control={<Checkbox name="checkedA" color="primary" checked={true} disabled={true}/>}
                            className={classes.label}
                            label={t('Required')}
                        />
                        <FormControlLabel
                            className={classes.label}
                            control={
                                <Checkbox
                                    name="diagnostics"
                                    color="primary"
                                    checked={diagnostics}
                                    onChange={onDiagnostics}
                                    data-sentrylabel="cookie-check-diagnostics"
                                />
                            }
                            label={t('Diagnostics')}
                            />
                        <Hide hide={!telemetryPossible}>
                            <FormControlLabel
                                className={classes.label}
                                control={
                                    <Checkbox
                                        name="checkedB"
                                        color="primary"
                                        defaultChecked={matomoActive.get()}
                                        onChange={onTelemetry}
                                        data-sentrylabel="cookie-check-statistics"
                                    />
                                }
                                label={t('Statistics')}
                            />
                        </Hide>
                        <Hide hide={!FEATURE_YOUTUBE_WARNING}>
                            <FormControlLabel
                                className={classes.label}
                                control={
                                    <Checkbox
                                        name="checkedC"
                                        color="primary"
                                        defaultChecked={youtube}
                                        onChange={onYoutube}
                                        data-sentrylabel="cookie-check-youtube"
                                    />
                                }
                                label="YouTube"
                            />
                        </Hide>
                    </FormGroup>
                </DialogContent>
                <div className={classes.actions}>
                    <LinkLikeText onClick={openLegalNotice} color="secondary">
                        {t('Legal notice')}
                    </LinkLikeText>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={apply}
                        data-sentrylabel="cookie-confirm-selection"
                        sx={{marginLeft: 2}}
                    >
                        {t('Confirm selection')}
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={selectAll}
                        data-sentrylabel="cookie-confirm-all"
                        sx={{marginLeft: 2}}
                    >
                        {t('Select all')}
                    </Button>
                </div>
            </Dialog>
        );
    };

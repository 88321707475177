import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import QRCode from 'qrcode.react';
import { useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { appStore } from '../stores/AppStore';
import ClipboardDialogStore from '../stores/ClipboardDialogStore';
import ToastStore from '../stores/ToastStore';
import theme from '../styles/theme';
import { TelemetryTrackEvent } from '../utils/Telemetry';
import { deviceSupportsFileExtension, openARLink } from '../viewers/ViewerAR';

import { isBrowserARAvailable } from '@egr/xbox/AR/Availability'
import Dialog from '@egr/xbox/egr-gui-dialogs/utils/DialogOverride';
import { inIframe } from '@egr/xbox/utils/UserAgent';
import { makeStyles, type MakeStylesHook, type StyleRules } from '@egr/xbox/utils/tss-react';

type ClipboardDialogClasses = 'code' | 'paper';

function getClipboardDialogStyles(): StyleRules<ClipboardDialogClasses> {
    return {
        code: {
            backgroundColor: theme.palette.grey[200],
            borderRadius: 2,
            display: 'block',
            padding: 16,
            wordBreak: 'break-all',
            lineHeight: '1.5em',
            outline: 0
        },
        paper: {
            maxWidth: 480
        }
    };
}

const useClipboardDialogStyles: MakeStylesHook<ClipboardDialogClasses> = makeStyles({ name: 'ClipboardDialog' })(getClipboardDialogStyles);

const ClipboardDialog: React.FC = () => {
    const { classes } = useClipboardDialogStyles();
    const { t } = useTranslation();
    const {showQR, show, title, content} = ClipboardDialogStore.state;
    const { urlInfo } = appStore.state;

    const clipboardTargetRef = useRef<HTMLElement>(null);

    const onEntering = useCallback((): void => {
        if (clipboardTargetRef.current) {
            clipboardTargetRef.current.setAttribute('contenteditable', 'true');
            const selection: Selection | null = window.getSelection();
            const range: Range = document.createRange();
            range.selectNodeContents(clipboardTargetRef.current);
            if (selection) {
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }
    }, []);

    const copyToClipboard = useCallback((): void => {
        if (clipboardTargetRef.current) {
            clipboardTargetRef.current.setAttribute('contenteditable', 'true');
            const selection: Selection | null = window.getSelection();
            const range: Range = document.createRange();
            range.selectNodeContents(clipboardTargetRef.current);
            if (selection) {
                selection.removeAllRanges();
                selection.addRange(range);
            }
            document.execCommand('copy');
            clipboardTargetRef.current.removeAttribute('contenteditable');
            ToastStore.open(t('Copied to clipboard'));

            if (clipboardTargetRef.current.textContent) {
                const isLink = clipboardTargetRef.current.textContent.toLowerCase().startsWith('http');
                TelemetryTrackEvent(isLink ? 'Share content' : 'Embed content');
            }
        }
    }, [t]);

    const showArOpenButton = urlInfo != null && showQR && isBrowserARAvailable && deviceSupportsFileExtension(urlInfo.extension);
    const showCloseButton = showQR && inIframe() ? false : true;

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            open={show}
            onClose={() => ClipboardDialogStore.close()}
            TransitionProps={{
                onEntering,
            }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <div style={{ display: (showQR ? 'block' : 'none'), textAlign: 'center', marginBottom: '24px' }}>
                    <QRCode value={content} data-sentrylabel="share-QRCode" />
                </div>
                <code className={classes.code} ref={clipboardTargetRef} data-sentrylabel="share-dialogContent">
                    {content}
                </code>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    style={{ fontWeight: 'bold', display: (showCloseButton ? 'block' : 'none') }}
                    onClick={() => ClipboardDialogStore.close()}
                >
                    {t('Close')}
                </Button>
                <Button color="primary" style={{ fontWeight: 'bold' }} onClick={copyToClipboard}>
                    {t('Copy')}
                </Button>
                <Button
                    color="primary"
                    style={{ fontWeight: 'bold', display: (showArOpenButton ? 'block' : 'none') }}
                    onClick={() => {
                        void openARLink();
                    }}
                >
                    {t('Open')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ClipboardDialog;

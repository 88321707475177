import { SyncExternalStore } from '@egr/xbox/utils/SyncExternalStore';

interface ClipboardDialogState {
  show: boolean;
  title: string;
  content: string;
  showQR: boolean;
  urlInServer: string;
}

class ClipboardDialogStore extends SyncExternalStore<ClipboardDialogState> {
  constructor() {
    super({
      show: false,
      title: '',
      content: '',
      showQR: false,
      urlInServer: '',
    });
  }
  private resolve: (value: boolean | PromiseLike<boolean>) => void;

  public open(
    title: string,
    content: string,
    showQR: boolean = false,
    urlInServer: string
  ): Promise<boolean> {
    this.updateState({
      show: true,
      title: title,
      content: content,
      showQR: showQR,
      urlInServer: urlInServer,
    });
    return new Promise((resolve) => {
      this.resolve = resolve;
    });
  }
  public close(): void {
    this.updateState({
      show: false,
    });
    if (this.resolve !== null && this.resolve !== undefined) {
      this.resolve(true);
    }
  }
}

export default new ClipboardDialogStore();

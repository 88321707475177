import i18next from 'i18next';
import { useCallback } from 'react';

import { appStore } from '../../../stores/AppStore';
import ClipboardDialogStore from '../../../stores/ClipboardDialogStore';
import { getEmbedUrl } from '../../../utils/utils';

export const useEmbed = () => {
    return useCallback(() => {
        const urlInfo = appStore.getUrlInfo();
        if (!urlInfo) {
            return;
        }
        const embedCode = `<iframe width="560" height="315" src="${getEmbedUrl(
            urlInfo.fileUrl,
            false
        )}" frameborder="0" allowfullscreen allowvr="yes" allow="xr-spatial-tracking"></iframe>`;
        void ClipboardDialogStore.open(i18next.t('Embed'), embedCode, false, '');
    }, []);
};

import i18next from 'i18next';
import { useCallback } from 'react';

import { appStore } from '../../../stores/AppStore';
import ClipboardDialogStore from '../../../stores/ClipboardDialogStore';
import { getEmbedUrl } from '../../../utils/utils';

export const useShare = () => {
    return useCallback(() => {
        const urlInfo = appStore.getUrlInfo();
        if (!urlInfo) {
            return;
        }
        const embedUrl = getEmbedUrl(urlInfo.fileUrl, false);
        void ClipboardDialogStore.open(i18next.t('Share'), embedUrl, true, '');
    }, []);
};

import type { MakeStylesHook, StyleRules} from '@egr/xbox/utils/tss-react';
import type { Theme } from '@mui/material/styles';

import Slide from '@mui/material/Slide';
import * as React from 'react';

import { dialogManager } from '../../stores/DialogManager';
import {visibilityDialogIframeStore} from '../../stores/StoreVisibilityDialogIFrame';

import { ModalIframeBar } from './ModalIframeBar';

import { makeStyles } from '@egr/xbox/utils/tss-react';

type ModalIframeClasses = 'container' | 'iframe';

function getModalIframeStyles(theme: Theme): StyleRules<{}, ModalIframeClasses> {
    return {
        container: {
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            left: 0,
            outline: 0,
            overflow: 'auto',
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: theme.zIndex.modal + 1,
        },
        iframe: {
            flexBasis: 'auto',
            flexGrow: 1,
            flexShrink: 1,
            overflow: 'auto',
            width: '100vw',
            '& iframe': {
                border: 0,
                height: '100%',
                margin: 0,
                outline: 0,
                width: '100%',
            }
        }
    };
}

const useModalIframeStyles: MakeStylesHook<ModalIframeClasses> = makeStyles({ name: 'ModalIframe' })(getModalIframeStyles);

export const ModalIframe: React.FC = () => {
        const { classes } = useModalIframeStyles();

        const [visible, setVisibility] = React.useState<boolean>(false);
        visibilityDialogIframeStore.setVisibility = setVisibility;

        const [height, setIframeHeight] = React.useState(0);
        const ref: React.MutableRefObject<HTMLDivElement | null> = React.useRef(null);

        React.useEffect(
            () => {
                if (ref.current != null) {
                    setIframeHeight(ref.current.getBoundingClientRect().height);
                }
            }
        );

        if (dialogManager.state.iframeUri == null) {
            return null;
        }

        if (!visible) { return (null); }
        return (
            <Slide direction="up" in={true} mountOnEnter={true} unmountOnExit={true}>
                <div className={classes.container}>
                    <ModalIframeBar />
                    <div className={classes.iframe} ref={ref} style={{overflow: 'hidden'}}>
                        <iframe src={dialogManager.state.iframeUri} style={{height}}/>
                    </div>
                </div>
            </Slide>
        );
    };
import type * as React from 'react';

import CodeIcon from '@mui/icons-material/Code';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ShareIcon from '@mui/icons-material/Share';
import Hidden from '@mui/material/Hidden';
import { useTranslation } from 'react-i18next';

import {viewerStore} from '../../../stores/ViewerStore';
import { useEmbed } from '../hooks/useEmbed';
import { useShare } from '../hooks/useShare';
import { useSnapshot } from '../hooks/useSnapshot';

import { AppBarButton } from './AppBarButton';
import { AppBarLanguageButton } from './AppBarLanguageButton';
import { AppBarMoreButton } from './AppBarMoreButton';

const iconStyle = {
    marginRight: '12px',
    width: 18,
    height: 18,
};

export const AppBarButtons: React.FC = () => {
    const [t] = useTranslation();
    const {viewerVisible} = viewerStore.state;

    const disabled: boolean = !viewerVisible;
    const handleShare = useShare();
    const handleEmbed = useEmbed();
    const handleSnapshot = useSnapshot();
    return (
        <>
            <Hidden mdDown={true}>
                <AppBarButton
                    onClick={handleShare}
                    disabled={disabled}
                    icon={<ShareIcon sx={iconStyle} />}
                    label={t('Share')}
                />
                <AppBarButton
                    onClick={handleEmbed}
                    disabled={disabled}
                    icon={<CodeIcon sx={iconStyle} />}
                    label={t('Embed')}
                />
                <AppBarButton
                    onClick={handleSnapshot}
                    disabled={disabled}
                    icon={<PhotoCameraIcon sx={iconStyle} />}
                    label={t('Snapshot')}
                />
                <AppBarLanguageButton iconStyle={iconStyle}/>
            </Hidden>
            <Hidden mdUp={true}>
                <AppBarLanguageButton iconStyle={iconStyle} />
                <AppBarMoreButton />
            </Hidden>
        </>
    );
};

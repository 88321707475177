import { SyncExternalStore } from '@egr/xbox/utils/SyncExternalStore';

interface VisibilityState {
    visibility: boolean;
}

class StoreVisibilityDialogCookies extends SyncExternalStore<VisibilityState> {
    constructor() {
        super({ visibility: false });
    }

    public visibility(): boolean {
        return this.snapshot.visibility;
    }

    public setVisibility(visibility: boolean) {
        this.updateState({ visibility });
    }
}

export const cookieStore = new StoreVisibilityDialogCookies();
import type { SelectChangeEvent } from '@mui/material/Select';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import * as React from 'react';

import { appStore } from '../stores/AppStore';

import { PUBLIC_URL } from '@egr/xbox/utils/ReactScriptHelper';

import { joinPath } from '@easterngraphics/wcf/modules/utils/string';
const links = new Map<string, string>([
    ['local - epan', joinPath(PUBLIC_URL, 'files', 'panorama.epan')],
    ['local - usdz', joinPath(PUBLIC_URL, 'files', 'bene.usdz')],
    ['local - iar', joinPath(PUBLIC_URL, 'files', 'stuhl.iar')],
    ['local - jpg', joinPath(PUBLIC_URL, 'files', 'panorama.jpg')],
    ['local - png', joinPath(PUBLIC_URL, 'files', 'impress_pano.jpg')],
    ['local - pec', joinPath(PUBLIC_URL, 'files', 'ahrend.pec')],
    ['OWDS - epan', 'test'],
    ['OWDS - usdz', 'test'],
    ['OWDS - iar', 'test'],
    ['OWDS - jpg', 'test'],
    ['OWDS - png', 'test'],
    ['OWDS - pec', 'test'],
    ['OneDrive - png', 'test'],
    ['OneDrive - usdz', 'test'],
    ['OneDrive - iar', 'test'],
    ['OneDrive - jpg', 'test'],
    ['OneDrive - png', 'test'],
    ['OneDrive - pec', 'test'],
]);

export const DebugFiles: React.FC = () => {
    const [currentValue, setCurrentValue] = React.useState<string>('');
    if (links.size === 0) {
        return null;
    }

    const onChange: (event: SelectChangeEvent) => void = (event) => {
        setCurrentValue(event.target.value);
        void appStore.setFileUrl(links.get(event?.target.value)!);
    };
    return (
        <React.Fragment>
            <Select sx={{ color: 'white' }} onChange={onChange} value={currentValue} label="Links">
                {[...links].map(([key]) => {
                    return (
                        <MenuItem key={key} value={key}>
                            {key}
                        </MenuItem>
                    );
                })}
            </Select>
        </React.Fragment>
    );
};

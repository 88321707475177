import { isNumber } from '@easterngraphics/wcf/modules/utils/number';
import { isNotNullOrEmpty, isString } from '@easterngraphics/wcf/modules/utils/string';

export function isNullOrZero(value?: number | null): value is null | undefined {
    return value == null || value === 0;
}

export function inputValueToNumber(value: string | number | boolean): number | undefined {
    let result: number | undefined;
    if (isString(value) && isNotNullOrEmpty(value)) {
        result = parseFloat(value);
    } else if (isNumber(value)) {
        result = value;
    }

    if (result == null || isNaN(result)) {
        return;
    }

    return result;
}

import type { UrlInfo } from '../utils/UrlInfo';

import { getUrlInfo } from '../utils/UrlInfo';

import { setLanguage } from '@egr/xbox/base-app/i18n';
import { withProgress } from '@egr/xbox/progress-manager/ProgressManager';
import { SyncExternalStore } from '@egr/xbox/utils/SyncExternalStore';

interface AppStoreState {
  embed: boolean;
  wizard: boolean;
  faq: boolean;
  language: string;
  urlInfo: UrlInfo | null;
  languages: Record<string, [string, string]>;
}

class AppStore extends SyncExternalStore<AppStoreState> {
  constructor() {
    super({
      embed: false,
      wizard: false,
      faq: false,
      language: 'en',
      urlInfo: null,
      languages: {
        da: ['DA', 'Dansk'],
        de: ['DE', 'Deutsch'],
        en: ['EN', 'English'],
        es: ['ES', 'Español'],
        fr: ['FR', 'Français'],
        it: ['IT', 'Italiano'],
        nl: ['NL', 'Nederlands'],
        pl: ['PL', 'Polskie'],
        pt: ['PT', 'Português'],
        ro: ['RO', 'Română'],
        tr: ['TR', 'Türkçe'],
        ru: ['RU', 'Русский'],
      },
    });
  }
  public setwizard(wizard: boolean) {
    this.updateState({ wizard });
  }

  public getEmbed(): boolean {
    return this.snapshot.embed;
  }

  public setEmbed(embed: boolean) {
    this.updateState({ embed });
  }

  public getFaq(): boolean {
    return this.snapshot.faq;
  }

  public setFaq(faq: boolean) {
    this.updateState({ faq });
  }

  public getLanguages() {
    return this.snapshot.languages;
  }

  public getLanguage(): string {
    return this.snapshot.language;
  }

  public setCurrentLanguage(languageKey: string) {
    void setLanguage(languageKey);
    this.updateState({ language: languageKey });
  }

  public getUrlInfo(): UrlInfo | null {
    return this.snapshot.urlInfo;
  }

  @withProgress('fetch urlInfo')
  public async fetchUrlInfo() {
    const urlInfo = await getUrlInfo(location.href, true);
    this.updateState({
      urlInfo,
      embed: urlInfo.embed,
    });
  }

  public async setFileUrl(url: string) {
    window.history.pushState('', '', '?url=' + encodeURIComponent(url));
    await this.fetchUrlInfo();
  }
}

export const appStore = new AppStore();

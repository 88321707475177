import wizard1 from '../../images/wizard1.jpg';
import wizard2 from '../../images/wizard2.jpg';
import wizard3 from '../../images/wizard3.jpg';
import wizard4 from '../../images/wizard4.jpg';
import wizard5 from '../../images/wizard5.jpg';

export const images = [
    {
      imgPath: wizard1,
      title: ('Impress with your Design'),
      text: ('Great designs deserve to be seen from all perspectives. impress displays 360° panoramas, brings 3D models to life and highlights products and their features with Multi Content Pictures.'),
    },
    {
      imgPath: wizard2,
      title: ('Share your Ideas'),
      text: ('Encourage people to explore your designs interactively with simple sharing options. For a literally vivid impression.'),
    },
    {
      imgPath: wizard3,
      title: ('Pimp your Presentation'),
      text: ('Add more emotions to your presentation by integrating interactive representations of your interior design.'),
    },
    {
      imgPath: wizard4,
      title: ('Master your Media'),
      text: ('Magnify your online media by embedding interactive 3D models, amazing 360° panoramas and value-adding MCPs. Make your website and blog unique.'),
    },
    {
      imgPath: wizard5,
      title: ('Get ready to impress'),
      text: ('Enter the public file link to your 3D model (PEC), 360° panorama (PNG, JPG, JPEG) or MCP (epan/eimg) and let your design speak for itself.'),
    },
  ];
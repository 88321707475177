export function setMatomoOptIn(trackingShouldBeEnabled: boolean): void {
    // console.warn('setMatomoOptIn', trackingShouldBeEnabled);
    if (window._paq != null) {
        trackingShouldBeEnabled
            ? window._paq.push(['rememberConsentGiven', (6 * 30 * 24).toString()])  // 6 months
            // ? window['_paq'].push(['rememberCookieConsentGiven', (1 * 30 * 24).toString()])  // 1 month
            : window._paq.push(['forgetConsentGiven']);
        // : window['_paq'].push(['forgetCookieConsentGiven']);
    }
}
type TelemetryCustomDimension1Values = 'direct call' | 'embedded';
export function TelemetrySetCustomDimension1(customDimensionValue: TelemetryCustomDimension1Values = 'direct call') {
    // console.log('TelemetrySetCustomDimension1', customDimensionValue );
    const customDimensionId: string = '1';
    if (window._paq != null) {
        window._paq.push(['setCustomDimension', customDimensionId, customDimensionValue]);
        window._paq.push(['requireConsent']);
        window._paq.push(['trackPageView']);
        window._paq.push(['enableLinkTracking']);
    } else {
        console.warn('window["_paq"] is null');
    }
}
type EventCategories = 'Impress content' | 'VR mode' | 'About' | 'Filehoster';
type matomoEventActions = 'open' | 'share' | 'embed' | 'enter' | 'cloud';
type TelemetryEvents = 'Load content' | 'Share content' | 'Embed content' | 'Virtual reality' | 'About' | 'Cloud';
function _TelemetryTrackEvent(Category: EventCategories, Action: matomoEventActions, Name?: string, Value?: string) {
    if (window._paq != null) {
        window._paq.push(['trackEvent', Category, Action, Name, Value]);
    } else {
        console.warn('window["_paq"] is null');
    }
}
export function TelemetryTrackEvent(event: TelemetryEvents, name?: string, value?: string) {
    if (window._paq != null) {
        switch (event) {
            case 'Load content':
                _TelemetryTrackEvent('Impress content', 'open', name, value);
                break;
            case 'Share content':
                _TelemetryTrackEvent('Impress content', 'share', name, value);
                break;
            case 'Embed content':
                _TelemetryTrackEvent('Impress content', 'embed', name, value);
                break;
            case 'Virtual reality':
                _TelemetryTrackEvent('VR mode', 'enter', name, value);
                break;
            case 'About':
                _TelemetryTrackEvent('About', 'open', name, value);
                break;
            case 'Cloud':
                _TelemetryTrackEvent('Filehoster', 'cloud', name, value);
                break;
            default:
        }

    } else {
        console.warn('window["_paq"] is null');
    }
}
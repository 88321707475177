import type { MakeStylesHook, StyleRules} from '@egr/xbox/utils/tss-react';
import type { Theme, TypographyVariant } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import * as React from 'react';

import { AppThemeContext } from '../AppThemeProvider/AppTheme';
import { PlaceholderBar } from '../ViewPlaceholder/Placeholders';

import { makeStyles } from '@egr/xbox/utils/tss-react';

type ToolbarLabelProps = React.PropsWithChildren<{
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    hideLabelOnSmallScreen?: boolean;
    centered?: boolean;
    // children: React.ReactNode | Array<React.ReactNode>;
    variant?: TypographyVariant | 'inherit';
    id?: string;
}>;

type ToolbarLabelClasses = 'root' | 'centered' | 'clickable' | 'hideIfOverflow' | 'hideWorkaround';

function getToolbarLabelStyles(theme: Theme, props: unknown, classes: Record<ToolbarLabelClasses, string>): StyleRules<{}, ToolbarLabelClasses> {
    return {
        root: {
            background: 'inherit',
            flexBasis: 'auto',
            flexGrow: 1,
            flexShrink: 1,
            height: '3rem',
            lineHeight: '3rem', // so it does not shrink the appbar height if no iconbutton is present (iconbutton height is 3rem)
            maxHeight: '3rem',
            overflow: 'hidden',
            position: 'relative',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            '& > *': {
                lineHeight: '3rem !important', // sometimes overriden by typography class
                textOverflow: 'ellipsis',
                overflow: 'hidden'
            },
            [`&.${classes.hideIfOverflow}`]: {
                textOverflow: 'unset',
                whiteSpace: 'normal',
                [`& .${classes.hideWorkaround}`]: {
                    display: 'inline-block',
                },
                '& > *': {
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    textOverflow: 'unset',
                    overflow: 'unset',
                }
            },
        },
        centered: {
            position: 'absolute',
            left: 0,
            zIndex: -1,
            width: '100%',
            textAlign: 'center',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            lineHeight: '3rem', // so it does not shrink the appbar height if no iconbutton is present (iconbutton height is 3rem)
            [theme.breakpoints.down('sm')]: {
                opacity: 0,
            }
        },
        clickable: {
            cursor: 'pointer',
        },
        hideIfOverflow: { /* */ },
        hideWorkaround: { display: 'none' },
    };
}

const useToolbarLabelStyles: MakeStylesHook<ToolbarLabelClasses> = makeStyles<void, ToolbarLabelClasses>({ name: 'ToolbarLabel' })(getToolbarLabelStyles);

export const ToolbarLabel: React.FC<ToolbarLabelProps> = React.memo((props: ToolbarLabelProps) => {
    const { classes, cx } = useToolbarLabelStyles();

    const { smallLayout } = React.useContext(AppThemeContext);

    const variant: TypographyVariant | 'inherit' = props.variant ?? 'h6';

    if (props.centered) {
        return (
            <React.Fragment>
                <Typography
                    onClick={props.onClick}
                    color="inherit"
                    variant={variant}
                    className={
                        cx(
                            classes.root,
                            props.onClick != null ? classes.clickable : undefined,
                        )
                    }
                    id={props.id}
                >
                    &nbsp;
                </Typography>
                <Typography
                    color="inherit"
                    variant={variant}
                    className={
                        cx(
                            classes.centered,
                            props.onClick != null ? classes.clickable : undefined,
                        )
                    }
                    id={props.id}
                >
                    <PlaceholderBar>
                        {props.children}
                    </PlaceholderBar>
                    &nbsp;
                </Typography>
            </React.Fragment>
        );
    }

    return (
        <div
            onClick={props.onClick}
            className={
                cx(
                    classes.root,
                    (props.hideLabelOnSmallScreen && smallLayout) ? classes.hideIfOverflow : undefined,
                    props.onClick != null ? classes.clickable : undefined,
                )
            }
        >
            <Typography variant={variant} className={classes.hideWorkaround}/>
            <Typography variant={variant} color="inherit">
                <PlaceholderBar>
                    {props.children}
                </PlaceholderBar>
            </Typography>
        </div>
    );
});
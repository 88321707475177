import * as React from 'react';

import { android } from '@egr/xbox/utils/UserAgent';

interface DialogContextProps {
    touchRipple: boolean;
    fullscreen?: boolean
}

const DialogContextDefault: DialogContextProps = {
    touchRipple: !android,
};

export const DialogContextFullscreen: DialogContextProps = {
    ...DialogContextDefault,
    fullscreen: true,
};

export const DialogContext: React.Context<DialogContextProps> = React.createContext<DialogContextProps>(DialogContextDefault);

DialogContext.displayName = 'DialogContext';
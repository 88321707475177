import type { StyleRules, WithStyles } from '@egr/xbox/utils/tss-react';
import type * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import logo from '../../images/logo25.svg';
import { isPhone, isPhoneNotchAtLeft, isPortrait } from '../../utils/ViewerUtilities';
import { DebugFiles } from '../DebugFiles';

import { AppBarButtons } from './AppBarButtons/AppBarButtons';

import { developmentMode } from '@egr/xbox/utils/ReactScriptHelper';
import { withStyles } from '@egr/xbox/utils/tss-react';

const styles = (): StyleRules<'titleBar' | 'logo' | 'title'> => ({
    titleBar: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    logo: {
        paddingRight: 28,
        cursor: 'pointer',
    },
    title: {
        cursor: 'pointer',
    },
});
const safeAreaTop: number = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sat'), 10);

const styleTypo: React.CSSProperties = {
    visibility: isPhone() && isPortrait() && safeAreaTop > 0 ? 'hidden' : 'visible',
};

const styleNotchAtLeft: React.CSSProperties = {
    paddingLeft: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sal'), 10) + 'px',
};

const styleHeader: React.CSSProperties = {
    width: '100%',
};

interface HeaderProps extends WithStyles<'titleBar' | 'logo' | 'title'> { }

const Header: React.FC<HeaderProps> = ({ classes }) => {
    const { t } = useTranslation();
    const [, setRenderTrigger] = useState(0);

    const goHome = () => {
        location.href = location.origin + location.pathname;
    };

    const updateComponentUI = () => {
        setRenderTrigger(prev => prev + 1);
    };

    useEffect(() => {
        window.addEventListener('resize', updateComponentUI);
        window.addEventListener('orientationchange', updateComponentUI);
        document.addEventListener('customfullscreenchange', updateComponentUI);

        return () => {
            window.removeEventListener('resize', updateComponentUI);
            window.removeEventListener('orientationchange', updateComponentUI);
            document.removeEventListener('customfullscreenchange', updateComponentUI);
        };
    }, []);

    return (
        <AppBar style={styleHeader}>
            <Toolbar style={isPhoneNotchAtLeft() ? styleNotchAtLeft : {}}>
                <div className={classes?.titleBar}>
                    <img src={logo} className={classes?.logo} onClick={goHome} />
                    <Typography
                        variant="h6"
                        className={classes?.title}
                        onClick={goHome}
                        style={styleTypo}
                    >
                        {t('impress')}
                    </Typography>
                </div>
                {developmentMode && <DebugFiles />}
                <AppBarButtons />
            </Toolbar>
        </AppBar>
    );
};

export default (withStyles(Header, styles));

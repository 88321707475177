import type { EaiwsGeometryExportSetting } from './GeometryExportFormats';
import type { EaiwsImageExportSetting } from './ImageExportFormats';
import type { ClassType } from './Types';

export interface FileFormatInfo {
    extension: string;
    mimetype: string;
}

// Note: do not export the next constants used for mapping the file formats
type FormatMapping = Map<string, Array<FileFormatClass>>;
const allInstances: Array<FileFormatClass> = [];
const mimeTypeMapping: FormatMapping = new Map();
const extensionMapping: FormatMapping = new Map();

function getMatchingFileClasses(mapping: FormatMapping, key: string): IterableIterator<FileFormatClass>;
function getMatchingFileClasses<T extends FileFormatClass>(mapping: FormatMapping, key: string, classType: ClassType<T>): IterableIterator<T>;
function *getMatchingFileClasses<T extends FileFormatClass>(mapping: FormatMapping, key: string, classType?: ClassType<T>): IterableIterator<FileFormatClass> {
    const formats: Array<FileFormatClass> | undefined = mapping.get(key);
    if (formats != null) {
        for (const format of formats) {
            if (classType == null || format instanceof classType) {
                yield format;
            }
        }
    }
}

/**
 * Note: a class instance is only returned if exactly one object was found matching the
 * specified `extension` and `classType` (optional)
 */
export function getFileClassByUniqueExtension<T extends FileFormatClass>(extension: string, classType: ClassType<T>): T | undefined {
    const fileFormats: Array<T> = Array.from(getMatchingFileClasses(extensionMapping, extension, classType));
    if (fileFormats.length === 1) {
        return fileFormats[0];
    }

    return undefined;
}

/**
 * Note: a class instance is only returned if exactly one object was found matching the
 * specified `mimeType` and `classType` (optional)
 */
export function getFileClassByUniqueMimeType(mimeType: string): FileFormatClass | undefined;
export function getFileClassByUniqueMimeType<T extends FileFormatClass>(mimeType: string, classType: ClassType<T>): T | undefined;
export function getFileClassByUniqueMimeType<T extends FileFormatClass>(mimeType: string, classType?: ClassType<T>): T | undefined {
    const fileFormats: Array<T> = Array.from(getMatchingFileClasses(mimeTypeMapping, mimeType, classType ?? FileFormatClass));
    if (fileFormats.length === 1) {
        return fileFormats[0];
    }

    return undefined;
}

export abstract class FileFormatClass implements FileFormatInfo {
    /**
     * Note: we need to ensure that this class is not assignable to sub classes without new properties.
     * Therefore each sub class has to defined this property.
     */
    protected abstract ensureTypeSafety: symbol;

    /**
     * Note: Instances of this class should only be created within child classes
     * and then only for the assignment of static properties!
     */
    protected constructor(
        public readonly extension: string,
        public readonly mimetype: string,
        public readonly additionalExtensions: ReadonlyArray<string> = []// only used for jpg/jpeg
    ) {
        allInstances.push(this);
        // mimeTypeMapping && extensionMapping are module globals
        for (const ext of [extension, ...additionalExtensions]) {
            if (extensionMapping.has(ext)) {
                extensionMapping.get(ext)!.push(this);
            } else {
                extensionMapping.set(ext, [this]);
            }
        }

        if (mimeTypeMapping.has(mimetype)) {
            mimeTypeMapping.get(mimetype)!.push(this);
        } else {
            mimeTypeMapping.set(mimetype, [this]);
        }
    }
}

export type ImageMimetype = 'image/jpeg' | 'image/png' | 'image/gif';

export function isImageMimetype(value: string): value is ImageMimetype {
    return ['image/jpeg', 'image/png', 'image/gif'].includes(value);
}

export class ImageFormat extends FileFormatClass {
    public static readonly JPG: ImageFormat = new ImageFormat('jpg', 'image/jpeg', ['jpeg'], [0xff, 0xd8, 0xff]);
    public static readonly PNG: ImageFormat = new ImageFormat('png', 'image/png', [], [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]);
    public static readonly GIF: ImageFormat = new ImageFormat('gif', 'image/gif', [], [0x47, 0x49, 0x46]);
    public static readonly SVG: ImageFormat = new ImageFormat('svg', 'image/svg+xml', [], []);

    public override readonly mimetype: ImageMimetype;

    protected ensureTypeSafety: symbol;

    protected constructor(extension: string, mimetype: string, additionalExtensions: Array<string>, public readonly magicNumbers: ReadonlyArray<number>) {
        super(extension, mimetype, additionalExtensions);
    }
}

export class SessionExportFormat extends FileFormatClass {
    public static readonly OBX: SessionExportFormat = new SessionExportFormat('obx', 'application/obx');
    public static readonly OBK: SessionExportFormat = new SessionExportFormat('obk', 'application/obk');

    protected ensureTypeSafety: symbol;
}

export class WellKnownFormat extends FileFormatClass {
    public static readonly PDF: WellKnownFormat = new WellKnownFormat('pdf', 'application/pdf');
    public static readonly TXT: WellKnownFormat = new WellKnownFormat('txt', 'text/plain');
    public static readonly MARKDOWN: WellKnownFormat = new WellKnownFormat('txt', 'text/markdown');
    public static readonly JSON: WellKnownFormat = new WellKnownFormat('txt', 'application/json');
    public static readonly HTML: WellKnownFormat = new WellKnownFormat('html', 'text/html');
    public static readonly DOC: WellKnownFormat = new WellKnownFormat('doc', 'application/msword', ['dot']);
    public static readonly DOCX: WellKnownFormat = new WellKnownFormat('docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
    public static readonly XLS: WellKnownFormat = new WellKnownFormat('xls', 'application/msexcel', ['xla']);
    public static readonly XLSX: WellKnownFormat = new WellKnownFormat('xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

    public static readonly IES: WellKnownFormat = new WellKnownFormat('ies', 'application/octet-stream');
    public static readonly IFC: WellKnownFormat = new WellKnownFormat('ifc', 'application/x-step');
    public static readonly LDT: WellKnownFormat = new WellKnownFormat('ldt', 'application/octet-stream');
    public static readonly MATZ: WellKnownFormat = new WellKnownFormat('matz', 'application/octet-stream');
    public static readonly RFA: WellKnownFormat = new WellKnownFormat('rfa', 'application/octet-stream');

    public static readonly ROLF: WellKnownFormat = new WellKnownFormat('rolf', 'application/octet-stream');
    public static readonly ULD: WellKnownFormat = new WellKnownFormat('uld', 'application/octet-stream');

    public static readonly ZIP: WellKnownFormat = new WellKnownFormat('zip', 'application/zip');
    public static readonly EOX: WellKnownFormat = new WellKnownFormat('eox', 'application/octet-stream');

    protected ensureTypeSafety: symbol;
}

export class ProjectContainerFormat extends FileFormatClass {
    public static readonly PBOX: ProjectContainerFormat = new ProjectContainerFormat('pbox', 'application/octet-stream');
    public static readonly OPL: ProjectContainerFormat = new ProjectContainerFormat('opl', 'application/octet-stream');

    protected ensureTypeSafety: symbol;
}

export class GeometryExportFormat extends FileFormatClass {
    public static readonly PEC = new GeometryExportFormat(
        'pec',
        'application/octet-stream',
        {
            format: 'PEC',
            'dwg.enabled': true,
            'preview.enabled': true,
        }
    );

    public static readonly PEC_NO_PRICES = new GeometryExportFormat(
        'pec',
        'application/octet-stream',
        {
            format: 'PEC',
            'dwg.enabled': true,
            'preview.enabled': true,
            omitPriceData: true,
        }
    );

    public static readonly PEC_LIGHT = new GeometryExportFormat(
        'pec',
        'application/octet-stream',
        {
            format: 'PEC',
            'dwg.enabled': false,
            'preview.enabled': true,
            omitPriceData: true,
        }
    );

    public static readonly DAE: GeometryExportFormat = new GeometryExportFormat(
        'dae',
        'model/vnd.collada+xml',
        {
            format: 'DAE'
        }
    );

    public static readonly DWG3D: GeometryExportFormat = new GeometryExportFormat(
        'dwg',
        'application/x-dwg',
        {
            format: 'DWG',
            hideSubArticles: false,
            materials: true,
            no2D: true,
            no3D: false,
            textures: true,
            edwg: true
        }
    );

    /** @deprecated use DWG3D instead */
    public static readonly DWG: GeometryExportFormat = GeometryExportFormat.DWG3D;

    public static readonly DWG2D: GeometryExportFormat = new GeometryExportFormat(
        'dwg',
        'application/x-dwg',
        {
            format: 'DWG',
            hideSubArticles: false,
            materials: true,
            no2D: false,
            no3D: true,
            textures: true,
            edwg: true
        }
    );

    public static readonly DWGALL: GeometryExportFormat = new GeometryExportFormat(
        'dwg',
        'application/x-dwg',
        {
            format: 'DWG',
            hideSubArticles: false,
            materials: true,
            no2D: false,
            no3D: false,
            textures: true,
            edwg: true
        }
    );

    public static readonly DXF: GeometryExportFormat = new GeometryExportFormat(
        'dxf',
        'application/dxf',
        {
            format: 'DWG',
            dxf: true,
            hideSubArticles: false,
            no2D: true,
            textures: true,
            materials: true
        }
    );

    public static FBX: GeometryExportFormat = new GeometryExportFormat(
        'fbx',
        'application/octet-stream',
        {
            format: 'FBX',
            hideSubArticles: false,
            scale: 1,
            textures: true,
            materials: true,
            no2D: true,
            textureToColor: false,
            duplicateFaces: false
        }
    );

    public static readonly GLB: GeometryExportFormat = new GeometryExportFormat(
        'glb',
        'model/gltf-binary',
        {
            format: 'GLTF',
            ascii: false,
            texTrans: true,
            centerXZ: true
        }
    );

    public static readonly GLTF: GeometryExportFormat = new GeometryExportFormat(
        'gltf',
        'model/gltf+json',
        {
            format: 'GLTF',
            ascii: true,
            texTrans: true,
            centerXZ: true
        }
    );

    public static OBJ: GeometryExportFormat = new GeometryExportFormat(
        'zip',
        'application/octet-stream',
        {
            format: 'OBJ',
            hideSubArticles: false,
            scale: 1.0,
            compression: false,
            materials: true, // if enabled gets zip file instead of obj
            textures: true // if enabled gets zip file instead of obj
        }
    );

    public static '3DS': GeometryExportFormat = new GeometryExportFormat(
        'zip',
        'application/octet-stream',
        {
            format: '3DS',
            hideSubArticles: false,
            textures: true
        }
    );

    public static readonly SKP: GeometryExportFormat = new GeometryExportFormat(
        'skp',
        'application/vnd.sketchup.skp',
        {
            format: 'SKP',
            hideSubArticles: false,
            no2D: true,
            textures: false,
            textureToColor: true
        }
    );

    public static readonly RVT: GeometryExportFormat = new GeometryExportFormat(
        'rgfx',
        'application/octet-stream',
        {
            format: 'GFX',
            revit: true,
            geometryExtensions: ['.rfa', '.dwg'],
            hideSubArticles: false,
            hierarchyMode: 'MatrixStack',
            'obx.enabled': true
        }
    );

    public static readonly USDZ: GeometryExportFormat = new GeometryExportFormat(
        'usdz',
        'model/vnd.usdz+zip',
        {
            format: 'USD',
            ascii: false,
            centerXZ: true
        }
    );

    public static readonly USDA: GeometryExportFormat = new GeometryExportFormat(
        'usda',
        'model/vnd.usdz+zip',
        {
            format: 'USD',
            ascii: true,
            centerXZ: true
        }
    );

    protected ensureTypeSafety: symbol;

    protected constructor(extension: string, mimetype: string, public readonly eaiwsSettings: EaiwsGeometryExportSetting) {
        super(extension, mimetype);
    }
}

export function isExportGeometryFormat(format: string): format is GetFormatKeys<typeof GeometryExportFormat> {
    return GeometryExportFormat[format] != null;
}

export type GeometryExportFormatKey = GetFormatKeys<typeof GeometryExportFormat>;
export type ImageExportFormatKey = GetFormatKeys<typeof ImageExportFormat>;
type GetFormatKeys<T> = Exclude<keyof T, 'prototype'>;

type FileFormat =
    GetFormatKeys<typeof GeometryExportFormat> |
    GetFormatKeys<typeof ImageExportFormat> |
    GetFormatKeys<typeof ImageFormat> |
    GetFormatKeys<typeof SessionExportFormat> |
    GetFormatKeys<typeof WellKnownFormat> |
    GetFormatKeys<typeof ProjectContainerFormat>;

export const FileFormats = new Map<FileFormat, FileFormatClass>(function *getFileFormats(): IterableIterator<[FileFormat, FileFormatClass]> {
    /**
     * all Classes used for FileFormat type
     */
    const classes: Array<object> = [ImageFormat, SessionExportFormat, WellKnownFormat, ProjectContainerFormat, GeometryExportFormat];
    const knownKeys = new Set<string>();

    for (const obj of classes) {
        for (const [key, value] of Object.entries(obj)) {
            if (!(value instanceof FileFormatClass)) {
                continue;
            }

            if (knownKeys.has(key)) {
                throw new Error('Duplicate file format key: ' + key);
            }
            knownKeys.add(key);

            yield [key as FileFormat, value];
        }
    }
}());

const imageExportFormatDefaults: Partial<EaiwsImageExportSetting> = {
    shadowPlane: true,
    'shadowPlane.color': '0.4 0.4 0.4',
    'shadowPlane.filter': 'DOF',
    ambient: '0.8 0.8 0.8',
    renderMode: 'PBR'
};

export class ImageExportFormat extends FileFormatClass {
    public static readonly JPG: ImageExportFormat = new ImageExportFormat(
        'jpg',
        'image/jgp+xml',
        {
            ...imageExportFormatDefaults,
            format: 'JPG',
        }
    );
    public static readonly PNG: ImageExportFormat = new ImageExportFormat(
        'png',
        'image/png+xml',
        {
            ...imageExportFormatDefaults,
            format: 'PNG',
        }
    );
    public static readonly TGA: ImageExportFormat = new ImageExportFormat(
        'stga',
        'image/tga+xml',
        {
            format: 'SVG',
        }
    );
    public static readonly TIFF: ImageExportFormat = new ImageExportFormat(
        'tiff',
        'image/tiff+xml',
        {
            format: 'TIFF',
        }
    );
    public static readonly SVG: ImageExportFormat = new ImageExportFormat(
        'svg',
        'image/svg+xml',
        {
            format: 'SVG',
        }
    );

    public static readonly PDF: ImageExportFormat = new ImageExportFormat(
        'pdf',
        'application/pdf',
        {
            format: 'PDF',
        }
    );

    public static readonly EPS: ImageExportFormat = new ImageExportFormat(
        'eps',
        'application/postscript',
        {
            format: 'EPS',
        }
    );

    public static readonly PS: ImageExportFormat = new ImageExportFormat(
        'ps',
        'application/postscript',
        {
            format: 'PS',
        }
    );
    public static readonly MPS: ImageExportFormat = new ImageExportFormat(
        'mps',
        'application/mps',
        {
            format: 'SVG',
        }
    );

    protected ensureTypeSafety: symbol;

    protected constructor(extension: string, mimetype: string, public readonly eaiwsSettings: EaiwsImageExportSetting) {
        super(extension, mimetype);
    }
}
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { LinkLikeText } from '@egr/xbox/egr-gui-elements/single-components/LinkLikeText';

interface CookieDialogTextProps {
    onLinkClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const CookieDialogText: React.FC<CookieDialogTextProps> = React.memo(
    (props: React.PropsWithChildren<CookieDialogTextProps>) => {
        const { t } = useTranslation();

        return (
            <Typography>
                <Trans
                    t={t}
                    i18nKey="To guarantee the best website experience for you, our website uses cookies. Please decide for yourself, which kind of cookies you want to allow. You will find further information in our <0>privacy policy</0>."
                    components={[(
                        <LinkLikeText onClick={props.onLinkClick} key="link" color="secondary">replace</LinkLikeText>
                    )]}
                />
            </Typography>
        );
    }
);
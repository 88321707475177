import type { ViewerUtility } from '../utils/ViewerUtility';

import { SyncExternalStore } from '@egr/xbox/utils/SyncExternalStore';

interface ViewerState {
  viewerVisible: boolean;
  experimental: boolean;
  viewerUtilities: ViewerUtility | null;
}

class ViewerStore extends SyncExternalStore<ViewerState> {
  constructor() {
    super({
      viewerVisible: false,
      experimental: false,
      viewerUtilities: null,
    });
  }

  public viewerVisible(): boolean {
    return this.snapshot.viewerVisible;
  }

  public setViewerVisible(viewerVisible: boolean): void {
    document.body.classList.add('ready');
    this.updateState({
      viewerVisible: viewerVisible,
    });
  }

  public experimental(): boolean {
    return this.snapshot.experimental;
  }

  public setViewerExperimental(experimental: boolean): void {
    this.updateState({
      experimental: experimental,
    });
  }

  public viewerUtility(): ViewerUtility | null {
    return this.snapshot.viewerUtilities;
  }

  public setViewerUtility(viewerUtility: ViewerUtility | null): void {
    this.updateState({
      viewerUtilities: viewerUtility,
    });
  }
}

export const viewerStore = new ViewerStore();

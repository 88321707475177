import * as React from 'react';

import { AppThemeContext } from '../AppThemeProvider/AppTheme';

type HideProps = React.PropsWithChildren<{
    hide?: boolean;
    phoneLandscape?: boolean;
    smallLayout?: boolean;
}>;

export const Hide: React.FC<HideProps> = React.memo((props) => {
        const { smallLayout, phoneLandscape } = React.useContext(AppThemeContext);

        if (
            props.hide ??
            ((props.phoneLandscape && phoneLandscape) ??
            (props.smallLayout && smallLayout))
        ) {
            return null;
        }
        return <React.Fragment>{props.children}</React.Fragment>;
    }
);
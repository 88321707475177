import type { MakeStylesHook, StyleRules} from '@egr/xbox/utils/tss-react';
import type { Theme, SxProps } from '@mui/material/styles';

import LanguageIcon from '@mui/icons-material/Language';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';

import {appStore} from '../../../stores/AppStore';

import { AppBarButton } from './AppBarButton';

import { makeStyles } from '@egr/xbox/utils/tss-react';

type AppBarLanguageButtonClasses = 'button' | 'icon' | 'listItemIcon';

function getAppBarLanguageButtonStyles(): StyleRules<{}, AppBarLanguageButtonClasses> {
    return {
        button: {
            lineHeight: '1.2em',
            fontWeight: 600,

        },
        icon: {
            marginRight: 12,
            width: 18,
            height: 18,
        },
        listItemIcon: {
            minWidth: 48,
        },
    };
}

const useAppBarLanguageButtonStyles: MakeStylesHook<AppBarLanguageButtonClasses> = makeStyles({ name: 'AppBarLanguageButton' })(getAppBarLanguageButtonStyles);

interface AppBarLanguageButtonProps {
    disabled?: boolean,
    iconStyle?: SxProps<Theme>;
}

export const AppBarLanguageButton: React.FC<AppBarLanguageButtonProps> = ({disabled, iconStyle}) => {
    const { classes } = useAppBarLanguageButtonStyles();
    const [open, setOpen] = React.useState(false);
    const ref: React.MutableRefObject<HTMLButtonElement | null> = React.useRef(null);

    const button: React.ReactNode = iconStyle == null ?
        <Tooltip title={appStore.state.languages[appStore.state.language][1]} placement="bottom">
            <span>
                <IconButton ref={ref} sx={{color: '#FFFFFF'}}  onClick={() => setOpen(true)}>
                    <LanguageIcon />
                </IconButton>
            </span>
        </Tooltip>
        :
        <AppBarButton
            ref={ref}
                onClick={() => setOpen(true)}
                disabled={disabled ?? false}
                icon={<LanguageIcon sx={iconStyle} />}
                label={appStore.state.languages[appStore.state.language][0]}
        />;
    return (
        <>
            {button}
            <Menu
                anchorEl={ref.current}
                open={open}
                onClose={()=> setOpen(false)}
            >
                {Object.keys(appStore.state.languages).map((language: string) => (
                    <MenuItem
                        key={language}
                        onClick={() => {
                            appStore.setCurrentLanguage(language);
                            setOpen(false);
                        }}
                    >
                        <ListItemIcon className={classes?.listItemIcon}>
                            {appStore.state.language === language ?
                                <RadioButtonCheckedIcon color="secondary" /> :
                                <RadioButtonUncheckedIcon color="primary" style={{color: '#868686'}} />
                            }
                        </ListItemIcon>
                        {<ListItemText primary={appStore.state.languages[language][0]} />}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
import type { MakeStylesHook} from '@egr/xbox/utils/tss-react';

import { makeStyles, getKeyframes } from '@egr/xbox/utils/tss-react';

type LoadingClasses = 'content' | 'withTransition';

export const useLoadingStyles: MakeStylesHook<LoadingClasses> = makeStyles({ name: 'Loading' })({
    content: {
        outline: 'none',
        pointerEvents: 'none',
        zIndex: 1,
        '&-absolute': {
            position: 'absolute',
        },
        '&-absolute-top': {
            position: 'absolute',
            top: 0
        },
        '&-default': {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
        },
        '&-left': {
            position: 'absolute',
            left: '0',
            top: '50%',
            transform: 'translateY(-50%)'
        },
        '&-transition': {
        }
    },
    withTransition: {
        animation: getKeyframes('0% { opacity: 0; } 99% { opacity: 0; } 100% { opacity: 1; }', '.5s')
    }
});
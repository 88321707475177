import type { CustomError } from '../utils/ErrorHandler';

import { SyncExternalStore } from '@egr/xbox/utils/SyncExternalStore';

interface ErrorMessageState {
  show: boolean;
  error?: CustomError;
}
class ErrorMessageStore extends SyncExternalStore<ErrorMessageState> {
  constructor() {
    super({ show: false });
  }

  public open(error: CustomError): void {
    this.updateState({
      show: true,
      error: error,
    });
  }

  public close(): void {
    this.updateState({
      show: false,
    });
  }

  public getErrrorMessage(): ErrorMessageState {
    return this.snapshot;
  }
}

export const errorMessageStore = new ErrorMessageStore();

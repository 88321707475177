import type * as React from 'react';

import { alpha } from '@mui/material/styles';

import { makeStyles } from '@egr/xbox/utils/tss-react';

export type ViewProps = React.PropsWithChildren<{
    visible: boolean;
    color?: 'default' | 'primary' | 'grey' | 'dark' | 'transparent';
    transition?: boolean;
    keepMounted?: boolean;
    className?: string;
}>;

const useViewStyles = makeStyles({ name: 'View' })((theme) => ({
    transition: {
        transition: 'transform 330ms',
    },
    root: {
        alignItems: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        maxHeight: '100vh',
        width: '100vw',
        WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
        position: 'absolute',
        top: 0,
        zIndex: 1,
        overflow: 'hidden',
        transform: 'translate3d(0, 0, 0)',
    },
    hideByMove: {
        transform: 'translate3d(0, 100vh, 0)',
        zIndex: 0,
    },
    hideByVisibility: {
        visibility: 'hidden',
        zIndex: 0,
    },
    colorDefault: {
        backgroundColor: theme.palette.background.default,
    },
    colorPrimary: {
        backgroundColor: alpha(theme.palette.primary.main, .3),
    },
    colorGrey: {
        backgroundColor: theme.palette.grey[300],
    },
    colorDark: {
        backgroundColor: theme.palette.grey[500],
    },
}));

const View: React.FC<ViewProps> = (props) => {
    const { classes, cx } = useViewStyles();

    if (!props.visible && !props.keepMounted) {
        return null;
    }

    function onClick(): void {
        /* */
    }

    return (
        <div
            className={cx(
                props.className,
                classes.root,
                props.color == null || props.color === 'default' ? classes.colorDefault : undefined,
                props.color === 'dark' ? classes.colorDark : undefined,
                props.color === 'grey' ? classes.colorGrey : undefined,
                props.color === 'primary' ? classes.colorPrimary : undefined,
                props.transition ? classes.transition : undefined,
                (props.transition && !props.visible) ? classes.hideByMove : undefined,
                (!props.transition && !props.visible) ? classes.hideByVisibility : undefined,
            )}
            onClick={onClick}
        >
            {props.children}
        </div>
    );
};

export default View;

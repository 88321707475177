import type { AjaxCustomErrorCallback} from '@easterngraphics/wcf/modules/utils/async';

import { ignoreAjaxErrorHandlerMessageCallback } from './AjaxErrorHandler';
import { canvasToBlob } from './Image';
import { fallbackAction } from './Promise';
import { corsProxyUrl } from './Services';
import { isDataUri } from './String';
import { ios, safari } from './UserAgent';

import { hasAppApi } from '@egr/xbox/app-api/Communication';

import { ajax } from '@easterngraphics/wcf/modules/utils/async';
import { isNullOrEmpty, isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

/**
 * Returns the content of the given url as ArrayBuffer.
 */
export function getContent(url: string, responseType?: 'arraybuffer', customErrorCallback?: AjaxCustomErrorCallback): Promise<ArrayBuffer>;
export function getContent(url: string, responseType: 'blob', customErrorCallback?: AjaxCustomErrorCallback): Promise<Blob>;
export function getContent(url: string, responseType: 'blob' | 'arraybuffer' = 'arraybuffer', customErrorCallback?: AjaxCustomErrorCallback): Promise<ArrayBuffer | Blob> {
    return ajax(
        'GET',
        url,
        null,
        { responseType },
        customErrorCallback
    );
}

export async function getContentWithCORSFallback(url: string, responseType?: 'arraybuffer'): Promise<ArrayBuffer | undefined>;
export async function getContentWithCORSFallback(url: string, responseType: 'blob'): Promise<Blob | undefined>;
export async function getContentWithCORSFallback(url: string, responseType: 'blob' | 'arraybuffer' = 'arraybuffer'): Promise<ArrayBuffer | Blob | undefined> {
    try {
        const reply: ArrayBuffer | Blob = await fallbackAction(
            (): Promise<ArrayBuffer | Blob> => {
                return ajax<ArrayBuffer | Blob>(
                    'GET',
                    url,
                    null,
                    {
                        responseType,
                        retryAttempts: 0,
                    },
                    ignoreAjaxErrorHandlerMessageCallback
                );
            },
            (): Promise<ArrayBuffer | Blob> => {
                return ajax<ArrayBuffer | Blob>(
                    'GET',
                    corsProxyUrl(url),
                    null,
                    {
                        responseType,
                        retryAttempts: 0,
                    },
                    ignoreAjaxErrorHandlerMessageCallback
                );
            },
            3000,
            6000
        );
        if (reply != null) {
            return reply;
        }
    } catch {
        // ignore
    }
    return;
}

/**
 * This function can be used to load or download content via an hidden iframe.
 */
export function loadHiddenFrame(url: string, removeAfter: number = 0): () => void {
    const iframe: HTMLIFrameElement = document.createElement('iframe');
    iframe.frameBorder = '0px';
    iframe.width = '0px';
    iframe.height = '0px';
    iframe.src = url;
    iframe.style.display = 'none';

    document.body.appendChild(iframe);

    let timeout: number | undefined;
    const removeFunc: () => void = () => {
        // Needed if the returned callback is used
        // to remove the frame from the DOM
        if (timeout !== undefined) {
            clearTimeout(timeout);
        }

        document.body.removeChild(iframe);
    };

    if (removeAfter > 0) {
        timeout = window.setTimeout(removeFunc, removeAfter);
    }

    return removeFunc;
}

async function toDataUrl(link: string, type: string = 'application/octet-stream'): Promise<string | undefined> {
    try {
        const content: ArrayBuffer = await getContent(link);
        const blob: Blob = new Blob([content], { type });
        return new Promise<string | undefined>((resolve) => {
            const fr: FileReader = new FileReader();
            fr.onerror = () => {
                resolve(undefined);
            };
            fr.onload = () => {
                resolve(fr.result as string | undefined);
            };
            fr.readAsDataURL(blob);
        });
    } catch {
        return;
    }
}

export async function forceDownloadUrl(link: string, filename: string): Promise<void> {
    const blob: Blob = await getContent(corsProxyUrl(link), 'blob');
    downloadBlob(blob, filename);
}

type AnchorTarget = '_blank' | '_self' | '_parent' | '_top';
export function downloadUrl(href: string | undefined, download: string, target: AnchorTarget, skipIOSHandling: true): void;
export async function downloadUrl(href: string | undefined, download?: string, target?: AnchorTarget, skipIOSHandling?: false): Promise<void>;
export async function downloadUrl(href: string | undefined, download?: string, target: AnchorTarget = '_blank', skipIOSHandling: boolean = false): Promise<void> {
    const useIOSworkaround = !skipIOSHandling && ios && !hasAppApi && target !== '_self';
    if (useIOSworkaround && isNotNullOrEmpty(href) && !isDataUri(href)) {
        href = await toDataUrl(href); // will prevent file opening of e.g. pdf in same window
    }

    if (isNullOrEmpty(href)) {
        return;
    }

    const anchor: HTMLAnchorElement = document.createElement('a');
    anchor.style.display = 'none';
    document.body.appendChild(anchor);
    anchor.setAttribute('href', href);
    anchor.setAttribute('target', target);
    if (isNotNullOrEmpty(download)) {
        anchor.setAttribute('download', download);
    }
    anchor.click();
    document.body.removeChild(anchor);
}

interface OldNavigator {
    msSaveBlob?: (data: Blob, filename: string) => void;
    msSaveOrOpenBlob?: (data: Blob, filename: string) => void;
}

export function downloadBlob(blob: Blob, filename: string, popup?: Window | null): void {
    if ((navigator as OldNavigator).msSaveBlob != null) {
        // IE
        (navigator as OldNavigator).msSaveBlob!(blob, filename);
    } else if ((navigator as OldNavigator).msSaveOrOpenBlob != null) {
        // IE
        (navigator as OldNavigator).msSaveOrOpenBlob!(blob, filename);
    } else {
        const url: string = URL.createObjectURL(blob);

        if (popup != null) {
            popup.location.href = url;
        } else {
            downloadUrl(url, filename, '_blank', true);
        }

        if (ios || popup) {
            window.setTimeout(() => URL.revokeObjectURL(url), 2000);
        } else {
            URL.revokeObjectURL(url);
        }
    }
}

declare global {
    interface Window {
        /**
         * available in IE
         */
        clipboardData: {
            setData: (value: string, data: unknown) => void;
        };
    }
}

export const copyImageToClipboardPossible: boolean = !safari && (
    (navigator.clipboard != null && window.ClipboardItem != null) ||
    window.clipboardData != null
);

async function getBlobFromImageUri(uri: string): Promise<Blob | null> {
    return new Promise((resolve) => {
        const img = new Image();
        const canvas =  document.createElement('canvas');
        img.onload = async () => {
            canvas.width = img.naturalWidth;
            canvas.height = img.naturalHeight;
            canvas.getContext('2d')?.drawImage(img, 0, 0);
            const blob = await canvasToBlob(canvas, 'image/png');
            resolve(blob);
        };
        img.onerror = () => resolve(null);
        img.src = uri;
    });
}

export async function copyImageUriToClipboard(uri: string, contentType: string): Promise<boolean> {
    try {
        if (
            navigator.clipboard != null &&
            window.ClipboardItem != null // not available in FF
        ) {
            let blobForCopy: Blob | undefined | null;
            if (contentType !== 'image/png') {
                blobForCopy = await getBlobFromImageUri(uri);
            } else {
                blobForCopy = await getContent(uri, 'blob');
            }
            if (blobForCopy == null) {
                return false;
            }
            await navigator.clipboard.write([new ClipboardItem({ ['image/png']: blobForCopy })]);
            return true;
        } else if (window.clipboardData != null) { // Internet Explorer
            window.clipboardData.setData(contentType, await getContent(uri, 'blob'));
            return true;
        }
    } catch (e) {
        /* */
    }
    return false;
}
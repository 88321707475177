import type { CSSObject } from 'tss-react';

import normal300ttf from './open-sans-v17-latin_cyrillic-300.ttf';
import normal300woff from './open-sans-v17-latin_cyrillic-300.woff';
import normal300woff2 from './open-sans-v17-latin_cyrillic-300.woff2';
import italic300ttf from './open-sans-v17-latin_cyrillic-300italic.ttf';
import italic300woff from './open-sans-v17-latin_cyrillic-300italic.woff';
import italic300woff2 from './open-sans-v17-latin_cyrillic-300italic.woff2';
import normal600ttf from './open-sans-v17-latin_cyrillic-600.ttf';
import normal600woff from './open-sans-v17-latin_cyrillic-600.woff';
import normal600woff2 from './open-sans-v17-latin_cyrillic-600.woff2';
import italic600ttf from './open-sans-v17-latin_cyrillic-600italic.ttf';
import italic600woff from './open-sans-v17-latin_cyrillic-600italic.woff';
import italic600woff2 from './open-sans-v17-latin_cyrillic-600italic.woff2';
import normal700ttf from './open-sans-v17-latin_cyrillic-700.ttf';
import normal700woff from './open-sans-v17-latin_cyrillic-700.woff';
import normal700woff2 from './open-sans-v17-latin_cyrillic-700.woff2';
import italic700ttf from './open-sans-v17-latin_cyrillic-700italic.ttf';
import italic700woff from './open-sans-v17-latin_cyrillic-700italic.woff';
import italic700woff2 from './open-sans-v17-latin_cyrillic-700italic.woff2';
import italic400ttf from './open-sans-v17-latin_cyrillic-italic.ttf';
import italic400woff from './open-sans-v17-latin_cyrillic-italic.woff';
import italic400woff2 from './open-sans-v17-latin_cyrillic-italic.woff2';
import normal400ttf from './open-sans-v17-latin_cyrillic-regular.ttf';
import normal400woff from './open-sans-v17-latin_cyrillic-regular.woff';
import normal400woff2 from './open-sans-v17-latin_cyrillic-regular.woff2';

/**
 * https://github.com/mui-org/material-ui/issues/24966
 */
export const OpenSansFontFaces: CSSObject = {
    '@font-face': {
        /* open-sans-300 - latin_cyrillic */
        fontFamily: '"Open Sans"',
        fontDisplay: 'swap',
        fontStyle: 'normal',
        fontWeight: 300,
        src: `url('${normal300woff2}') format('woff2'), url('${normal300woff}') format('woff'), url('${normal300ttf}') format('truetype')`
    },
    fallbacks: [
        {
            /* open-sans-300italic - latin_cyrillic */
            '@font-face': {
                fontFamily: '"Open Sans"',
                fontDisplay: 'swap',
                fontStyle: 'italic',
                fontWeight: 300,
                src: `url('${italic300woff2}') format('woff2'), url('${italic300woff}') format('woff'), url('${italic300ttf}') format('truetype')`
            }
        },
        {
            '@font-face': {
                /* open-sans-regular - latin_cyrillic */
                fontFamily: '"Open Sans"',
                fontDisplay: 'swap',
                fontStyle: 'normal',
                fontWeight: 400,
                src: `url('${normal400woff2}') format('woff2'), url('${normal400woff}') format('woff'), url('${normal400ttf}') format('truetype')`
            },
        },
        {
            '@font-face': {
                /* open-sans-italic - latin_cyrillic */
                fontFamily: '"Open Sans"',
                fontDisplay: 'swap',
                fontStyle: 'italic',
                fontWeight: 400,
                src: `url('${italic400woff2}') format('woff2'), url('${italic400woff}') format('woff'), url('${italic400ttf}') format('truetype')`
            },
        },
        {
            '@font-face': {
                /* open-sans-600 - latin_cyrillic */
                fontFamily: '"Open Sans"',
                fontDisplay: 'swap',
                fontStyle: 'normal',
                fontWeight: 600,
                src: `url('${normal600woff2}') format('woff2'), url('${normal600woff}') format('woff'), url('${normal600ttf}') format('truetype')`
            },
        },
        {
            '@font-face': {
                /* open-sans-600italic - latin_cyrillic */
                fontFamily: '"Open Sans"',
                fontDisplay: 'swap',
                fontStyle: 'italic',
                fontWeight: 600,
                src: `url('${italic600woff2}') format('woff2'), url('${italic600woff}') format('woff'), url('${italic600ttf}') format('truetype')`
            },
        },
        {
            '@font-face': {
                /* open-sans-700 - latin_cyrillic */
                fontFamily: '"Open Sans"',
                fontDisplay: 'swap',
                fontStyle: 'normal',
                fontWeight: 700,
                src: `url('${normal700woff2}') format('woff2'), url('${normal700woff}') format('woff'), url('${normal700ttf}') format('truetype')`
            },
        },
        {
            '@font-face': {
                /* open-sans-700italic - latin_cyrillic */
                fontFamily: '"Open Sans"',
                fontDisplay: 'swap',
                fontStyle: 'italic',
                fontWeight: 700,
                src: `url('${italic700woff2}') format('woff2'), url('${italic700woff}') format('woff'), url('${italic700ttf}') format('truetype')`
            }
        }
    ]
};
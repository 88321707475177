import type { MakeStylesHook } from '@egr/xbox/utils/tss-react';

import * as React from 'react';

import { safeArea } from '../Helper/StyleHelper';

import { appInfo } from '@egr/xbox/app-api/AppInfo';
import { makeStyles } from '@egr/xbox/utils/tss-react';

export interface SafeAreaBottomSpacer {
    style?: React.CSSProperties;
}

export const SafeAreaBottomSpacer: React.FC<SafeAreaBottomSpacer> = React.memo(
    (props: SafeAreaBottomSpacer) => {
        const { classes } = useSafeAreaBottomSpacerStyle();
        if (appInfo?.hideBottomSpacer) {
            return null;
        }
        return (
            <div className={classes.root} style={props.style}/>
        );
    }
);

const useSafeAreaBottomSpacerStyle: MakeStylesHook<'root'> = makeStyles({ name: 'SafeAreaBottomSpacer' })({
    root: {
        ...safeArea('height', 'bottom'),
        flexShrink: 0,
    }
});
import { appStore } from '../stores/AppStore';

let fullScreenIsEmulated: boolean = false;

export const elementIdRootViewerMarzipano: string = 'elementIdRootViewerMarzipano';

export function getHTMLElement_Root(): HTMLElement | null {
    return document.getElementById('root');
}
export function getHTMLElement_ViewerContainer(): HTMLElement | null {
    return document.getElementById('viewer-container');
}
const getValue: (element: Element | null) => string =
    (element: Element | null): string => {
        if (element == null) { return ''; }
        if (element.innerHTML != null) { return element.innerHTML; }
        if (element.textContent != null) { return element.textContent; }
        return '';
    };

export enum descriptionEnum { default = 'default', short = 'short', long = 'long', features = 'features' }

export enum articleNumberEnum { base = 'base', final = 'final', varcode = 'varcode', ofmlvarcode = 'ofmlvarcode' }

export interface ArticleLink { title: string; url: string; }

export const getComID: (element: Element) => string = (article: Element): string => {
    let comID: string | null = '';
    if (article != null) {
        const elementsComID: Array<Element> = getChildrenByTagName(article, 'comID');
        const firstElementComID: Element = elementsComID[0];
        comID = getValue(firstElementComID);
    }
    return comID;
};
export const getArticle: (documentArticlesObx: Document | null, comID: string) => Element | null =
    (documentArticlesObx: Document | null, comID: string): Element | null => {
        if (documentArticlesObx === null) { return null; }

        const articles: Array<Element> = getArticles(documentArticlesObx);

        for (const element of articles) {
            if (element.getAttribute('basketId') === comID) {
                return element;
            }
        }

        return null;
    };

function getArticles(documentArticlesObx: Document | null): Array<Element> {
    if (documentArticlesObx === null) { return []; }
    const roots: Array<Element> = getChildrenByTagName(documentArticlesObx, 'cutBuffer');
    if (roots.length > 0) {
        const root: Element = roots[0];
        const items: Element = getChildrenByTagName(root, 'items')[0];
        let articles: Array<Element> = [];
        articles = articles.concat(Array.from(items.getElementsByTagName('bskArticle')));
        articles = articles.concat(Array.from(items.getElementsByTagName('usrArticle')));
        return articles;
    }
    return [];
}
export function getChildrenByTagName(element: Document | Element | undefined | null, tagName: string): Array<Element> {
    const a: Array<Element> = [];
    if (element === undefined || element === null || tagName === '') { return a; }
    if (element.children.length === 0) { return a; }
    Array.from(element.children).forEach((e, i) => {
        if (e.tagName === tagName) {
            a.push(e);
        }
    });
    return a;
}
export const getArticleNumber: (article: Element, artNumType: articleNumberEnum) => string = (article: Element, artNumType: articleNumberEnum): string => {
    let artNumber: string | null = '';
    if (article != null) {
        const artNrElements: Array<Element> = getChildrenByTagName(article, 'artNr');
        if (artNrElements.length === 1) {
            return getValue(artNrElements[0]);
        }
        artNrElements.forEach((a) => {
            if (a.getAttribute('type') === artNumType) {
                artNumber = getValue(a);
            }
        });
        if (artNumber === '') {
            return getValue(artNrElements[0]);
        }
    }

    return artNumber;
};
const getManufacturerName: (article: Element, lang: string, langDefault: string, id?: boolean) => string =
    (article: Element, lang: string, langDefault: string, id?: boolean): string => {

        const manufacturerElements: Array<Element> = getChildrenByTagName(article, 'manufacturer');
        if (manufacturerElements.length < 1) { return ''; }

        const manufacturer: Element = manufacturerElements[0];
        const names: Array<Element> = getChildrenByTagName(manufacturer, 'name');

        let nameEn: string | null = null;
        if (id) {
            return manufacturer.id;
        }

        if (names.length === 1) {
            return getValue(names[0]); // default
        }

        for (const name of names) {
            if (name.getAttribute('lang') === langDefault) {
                nameEn = getValue(name);
            }
            if (name.getAttribute('lang') === lang) {
                return getValue(name);
            }
        }

        if (nameEn !== null) {
            return nameEn;
        }

        if (manufacturer.id !== null) {
            return manufacturer.id;
        }
        return '';
    };
const getSeries: (article: Element, lang: string, langDefault: string, byId?: boolean) => string =
    (article: Element, lang: string, langDefault: string, id?: boolean): string => {
        const seriesElements: Array<Element> = getChildrenByTagName(article, 'series');
        if (seriesElements.length < 1) { return ' - '; }
        const series: Element = seriesElements[0];
        const names: Array<Element> = getChildrenByTagName(series, 'name');

        let nameEn: string | null = null;
        if (id) {
            return series.id;
        }

        if (names.length === 1) {
            return getValue(names[0]); // default
        }

        for (const name of names) {
            if (name.getAttribute('lang') === langDefault) {
                nameEn = getValue(name);
            }
            if (name.getAttribute('lang') === lang) {
                return getValue(name);
            }
        }

        if (nameEn !== null) {
            return nameEn;
        }

        if (series.id !== null) {
            return series.id;
        }
        return '';
    };

export const getDescriptionText: (article: Element, lang: string, defLang: string, typ: descriptionEnum) => string =
    (article: Element, lang: string, defLang: string, typ: descriptionEnum): string => {

        const descriptions: Array<Element> = getChildrenByTagName(article, 'description');

        let description: Element | null = null;

        for (const d of descriptions) {
            switch (typ) {
                case descriptionEnum.long:
                    if (d.getAttribute('type') === descriptionEnum.long) { description = d; }
                    break;
                case descriptionEnum.short:
                    if (d.getAttribute('type') === descriptionEnum.short) { description = d; }
                    break;
                case descriptionEnum.features:
                    if (d.getAttribute('type') === descriptionEnum.features) { description = d; }
                    break;
                case descriptionEnum.default:
                default:
                    if (d.getAttribute('default') === '1') { description = d; }
            }
        }
        if (description == null) { return ''; }

        if (description.children.length === 1) { return getValue(description.children[0]); }

        let textDefaultLang: string | null = null;
        for (const textElement of Array.from(description.children)) {
            if (textElement.getAttribute('lang') === lang) { return getValue(textElement); }
            if (textElement.getAttribute('lang') === defLang) { textDefaultLang = getValue(textElement); }
        }
        return textDefaultLang ?? '';
    };

export function getAllPositioningElements(documentObjectsXml: Document | null): Array<Element> {
    // Some objects also contain children articles
    if (documentObjectsXml === null) { return []; }
    const eoxObjects: Array<Element> = getChildrenByTagName(documentObjectsXml, 'eoxObjects');
    if (eoxObjects.length === 0) { return []; }
    const objects: Array<Element> = getChildrenByTagName(eoxObjects[0], 'object');
    let objectsOrItsChildren: Array<Element> = [];
    objects.forEach((o, i) => {
        const ch: Array<Element> = Array.from(o.getElementsByTagName('child'));
        if (ch.length > 0) {
            objectsOrItsChildren = objectsOrItsChildren.concat(ch);
        } else {
            objectsOrItsChildren.push(o);
        }
    });

    return objectsOrItsChildren;
}
const getCodeId: (article: Element) => string =
    (article: Element): string => {
        if (article != null) {

            const codeId: string | null =
                getManufacturerName(article, '', '', true) + '_' +
                getSeries(article, '', '', true) + '_' +
                getArticleNumber(article, articleNumberEnum.base) + '_' +
                getArticleNumber(article, articleNumberEnum.final) + '_' +
                getArticleNumber(article, articleNumberEnum.varcode) + '_' +
                getArticleNumber(article, articleNumberEnum.ofmlvarcode);

            return codeId;
        }
        return '';
    };
const getArticleLink: (parent: Document | Element) => ArticleLink | null = (parent: Document | Element): ArticleLink | null => {
    let link: ArticleLink | null = null;
    const appDataElements: Array<Element> = getChildrenByTagName(parent, 'appData');
    if (appDataElements.length > 0) {
        const appDataElement: Element = appDataElements[0];

        const applicationelements: Array<Element> = getChildrenByTagName(appDataElement, 'application');
        let applicationKeyInteractiveImage: Element | null = null;
        applicationelements.forEach((e, i) => {
            if (e.getAttribute('key') === 'InteractiveImage') {
                applicationKeyInteractiveImage = e;
                if (applicationKeyInteractiveImage !== null) {
                    const linkElements: Array<Element> = getChildrenByTagName(applicationKeyInteractiveImage, 'link');
                    if (linkElements.length > 0) {
                        const linkElement: Element = linkElements[0];
                        const title: string | null = linkElement.getAttribute('title');
                        const url: string | null = linkElement.textContent;
                        if (title !== null && url !== null) {
                            link = { title: title, url: url };
                        }
                    }
                }
            }
        });
    }
    return link;
};
export function getArticleLinkFromDocument(documentObx: Document, article: Element): ArticleLink | null {
    /* link is not necessarily in the first article */
    /* we search for the link in all the other articles  */
    const codeIdSearchingFor: string = getCodeId(article);

    const articles: Array<Element> = getArticles(documentObx);
    for (const element of articles) {
        const codeIdCurrent: string = getCodeId(element);
        if (codeIdCurrent === codeIdSearchingFor) {
            const articleLink: ArticleLink | null = getArticleLink(element);
            if (articleLink != null) {
                return articleLink;
            }
        }
    }

    return null;
}
export function getManufacturerNameFromDocument(documentObx: Document, article: Element, lang: string, langDefault: string, byId?: boolean): string {
    /* name is not necessarily in the first article */
    /* we search for the name in all the other articles  */
    const manufacturerId: string = getManufacturerName(article, '', '', true);
    if (byId) { return manufacturerId; }

    const articles: Array<Element> = getArticles(documentObx);

    for (const element of articles) {
        const name: string = getManufacturerName(element, lang, langDefault, false);
        const id: string = getManufacturerName(element, '', '', true);
        if (manufacturerId === id && name !== manufacturerId) {
            return name;
        }
    }

    return manufacturerId;
}
export function getSeriesNameFromDocument(documentObx: Document, article: Element, lang: string, langDefault: string, byId?: boolean): string {
    /* name is not necessarily in the first article */
    /* we search for the name in all the other articles  */
    const seriesId: string = getSeries(article, '', '', true);
    if (byId) { return seriesId; }

    const articles: Array<Element> = getArticles(documentObx);

    for (const element of articles) {
        const name: string = getSeries(element, lang, langDefault, false);
        const id: string = getSeries(element, '', '', true);
        if (seriesId === id && name !== seriesId) {
            return name;
        }
    }

    return seriesId;
}
export function decodeHtml(html: string) {
    const txt: HTMLTextAreaElement = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
}
export function isMobileDevice(): boolean {
    if (
        navigator.userAgent.match(/Android/i) != null
        || navigator.userAgent.match(/webOS/i) != null
        || navigator.userAgent.match(/iPhone/i) != null
        || navigator.userAgent.match(/iPad/i) != null
        || navigator.userAgent.match(/iPod/i) != null
        || navigator.userAgent.match(/BlackBerry/i) !=null
        || navigator.userAgent.match(/Windows Phone/i) !=null
    ) {
        return true;
    }
    return false;
}
export function isPhone(): boolean {
    if (isGalaxyTab()) { return false; }

    if (
        navigator.userAgent.match(/Android/i) != null
        || navigator.userAgent.match(/webOS/i) != null
        || navigator.userAgent.match(/iPhone/i) != null
        || navigator.userAgent.match(/iPod/i) != null
        || navigator.userAgent.match(/BlackBerry/i) != null
        || navigator.userAgent.match(/Windows Phone/i) != null
    ) {
        return true;
    }
    return false;
}
export function isiPhone(): boolean {
    if (
        navigator.userAgent.match(/iPhone/i) != null
    ) {
        return true;
    }
    return false;
}
export function isiPad(): boolean {
    if (
        navigator.userAgent.match(/iPad/i) != null
    ) {
        return true;
    }
    return false;
}
export function isiPadPro(): boolean {
    return isiPad();
}
function isGalaxyTab(): boolean {
    if (
        navigator.userAgent.match(/SM-T/i) != null
    ) {
        return true;
    }
    return false;
}
export function isPhoneLandscape(): boolean {
    return isPhone() && !isPortrait();
}
export function isPhonePortrait(): boolean {
    return isPhone() && isPortrait();
}
export interface SafeArea {
    top: number; bottom: number; left: number; right: number;
}
export function getSafeArea(): SafeArea {
    const sat: number = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sat'), 10);
    const sab: number = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sab'), 10);
    const sal: number = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sal'), 10);
    const sar: number = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sar'), 10);

    return {
        top: isNaN(sat) ? 0 : sat,
        bottom: isNaN(sab) ? 0 : sab,
        left: isNaN(sal) ? 0 : sal,
        right: isNaN(sar) ? 0 : sar,
    };
}

export function isStandAloneApp(): boolean {
    if (!(isPhone() || isMobileDevice())) { return false; }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isInWebAppiOS: boolean = ((window.navigator as any).standalone === true);
    const isInWebAppChrome: boolean = (window.matchMedia('(display-mode: standalone)').matches);
    return isInWebAppiOS || isInWebAppChrome;
}

export function isLandscape(): boolean {
    return window.matchMedia('(orientation: landscape)').matches;
}
export function isPortrait(): boolean {
    return window.matchMedia('(orientation: portrait)').matches;
}
export function isPhoneNotchAtLeft(): boolean {
    return isPhone() && parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sal'), 10) > 0 &&
        (window.orientation === 90);
}

export function isFirefox(): boolean {
    if (navigator.userAgent.match(/Firefox/i)) {
        return true;
    }
    return false;
}
export function isSafary(): boolean {
    return navigator.userAgent.indexOf('Safari') > -1;
}
export function isFullScreenSupported(): boolean {
    return true; // device and emulated
}
export const exitFullscreen: () => Promise<void> = async (): Promise<void> => {
    if (document.exitFullscreen) {
        await document.exitFullscreen();
    } else {
        fullScreenIsEmulated = false;

        SetPhonesBarVisibility(true);
        window.dispatchEvent(new Event('customfullscreenchange'));
    }
    window.dispatchEvent(new UIEvent('orientationchange'));

};
export const openFullscreen: (elem: HTMLElement) => Promise<void> = async (elem: HTMLElement): Promise<void> => {

    // It must be in the same domain
    // This file with an IFrame trying to go into full screen will fail:
    // file:///C:/Users/epereyra/Desktop/TASK%20Impress/_TASKS/2020.12%20iframes/IFRAME/one%20iframe.html

    // On IFrame, attribute AllowFullscreen must be present!

    if (elem.requestFullscreen) {
        await elem.requestFullscreen();
    } else {
        // In physical devices: iPhone (tab and stand-alone), iPad (stand-alone mode only)
        fullScreenIsEmulated = true;
        SetPhonesBarVisibility(false);
        // some events are not triggered in some devices
        window.dispatchEvent(new UIEvent('orientationchange'));
        // window.dispatchEvent(new UIEvent('resize'));
        window.dispatchEvent(new Event('customfullscreenchange'));
    }

};
export function IsCurrentViewerRootElementInFullscreen(): boolean {
    return isElementInFullScreen(getHTMLElement_ViewerContainer());
}
export function IsIFrame(): boolean {
    return !(window.self === window.top);
}

export function isElementInFullScreen(element: HTMLElement | null): boolean {
    if (isiPhone() || (isiPad() && isStandAloneApp())) { return fullScreenIsEmulated; }
    // tslint:disable-next-line: no-string-literal
    const fullScreenElement: Element | null = document.fullscreenElement;

    return ((fullScreenElement !== null) && (element !== null) && (fullScreenElement === element));

}

function SetPhonesBarVisibility(visible: boolean) {

    const header: HTMLElement | null = getHeader();
    if (header !== null) {
        header.style.display = visible ? 'flex' : 'none';

    }
    const footer: HTMLElement | null = getFooter();
    if (footer !== null) {
        footer.style.display = visible ? 'block' : 'none';
    }

}
export function getComputedHeightForHeader(): number {
    if (appStore.getEmbed() || IsCurrentViewerRootElementInFullscreen()) { return 0; }
    const header: HTMLElement | null = getHeader();
    if (header !== null) {
        return parseInt(getComputedStyle(header).height, 10);
    }
    return 0;
}
export function getComputedHeightForFooter(): number {
    if (appStore.getEmbed() || IsCurrentViewerRootElementInFullscreen()) { return 0; }
    const footer: HTMLElement | null = getFooter();
    if (footer !== null) {
        return parseInt(getComputedStyle(footer).height, 10);
    }
    return 0;
}

function getHeader(): HTMLElement | null {
    const headers: HTMLCollectionOf<HTMLElement> = document.getElementsByTagName('header');
    if (headers.length > 0) {
        return headers[0];
    }
    return null;
}

function getFooter(): HTMLElement | null {
    const footers: HTMLCollectionOf<HTMLElement> = document.getElementsByTagName('footer');
    if (footers.length > 0) {
        return footers[0];
    }
    return null;
}

export async function loadImage(src: string, img: HTMLImageElement): Promise<HTMLImageElement | null> {
    return new Promise((resolve, reject) => {
        img.onload = () => {
            resolve(img);
        };
        img.onerror = reject;
        img.src = src;
    });
}

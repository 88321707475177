import type { MakeStylesHook, StyleRules } from '@egr/xbox/utils/tss-react';
import type { Theme } from '@mui/material/styles';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LinkIcon from '@mui/icons-material/Link';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import logo from '../images/logo100.svg';
import { appStore } from '../stores/AppStore';
import theme from '../styles/theme';
import { TelemetryTrackEvent } from '../utils/Telemetry';
import { isiPhone } from '../utils/ViewerUtilities';

import AboutSlider from './AboutSlider/AboutSlider';
import Wrapper from './Wrapper';

import { makeStyles } from '@egr/xbox/utils/tss-react';

type classesUrlBar = 'root' | 'icon' | 'input' | 'inputInput' | 'action' | 'logo' | 'welcome' | 'correct';
function styles(t: Theme): StyleRules<classesUrlBar> {
    return {
        root: {
            marginLeft: t.spacing(3),
            marginRight: t.spacing(3),
            marginTop: t.spacing(2),
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            zIndex: 5,
        },
        icon: {
            marginLeft: 12,
            color: '#868686',
        },
        input: {
            alignItems: 'center',
            display: 'flex',
        },
        inputInput: {
            flex: 1,
            marginLeft: t.spacing(3),
            marginRight: t.spacing(3),
            paddingBottom: 0,
            paddingTop: 0,
            height: 48,
        },
        [t.breakpoints.down('sm')]: {
            root: {
                marginLeft: t.spacing(2),
                marginRight: t.spacing(2),
            },
            inputInput: {
                marginLeft: t.spacing(2),
                marginRight: t.spacing(2),
            },
        },
        action: {
            position: 'relative',
            width: 40,
            height: 48,
            borderRadius: '0px 4px 4px 0px',
            cursor: 'pointer',
        },
        logo: {
            display: 'unset',
            height: undefined,
            '@media (max-height: 325px)': {
                display: 'none',
            },
            '@media (min-height: 326px) and (max-height: 570px)': {
                // iphone SE 568
                height: '40px',
            },
        },
        welcome: {
            marginTop: '25px',
            marginBottom: '30px',
            '@media (max-height: 281px)': {
                margin: '10px',
            },
        },
        correct: {
            // corrects and centre the text vertically (iPhone issue)
            '& input': {
                height: '22px',
                maxHeight: '22px',
                lineHeight: '22px',
                overflow: 'visible',
                paddingBottom: isiPhone() ? '2px' : undefined,
                paddingLeft: '16px',
                fontSize: 16,
            },
        },
    };
}

const useUrlClassStyles: MakeStylesHook<classesUrlBar> = makeStyles({ name: 'UrlClass' })(styles);

const UrlComp : React.FC = () => {
    const { classes } = useUrlClassStyles();
    const { t } = useTranslation();
    const [hasChanged, setHasChanged] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const setInput = useCallback((node: HTMLInputElement | null) => {
        if (node) {
            node.setAttribute('spellcheck', 'false');
        }
        inputRef.current = node;
    }, []);

    const onFocus = useCallback(() => {
        window.setTimeout(() => inputRef.current?.setSelectionRange(0, inputRef.current.value.length), 100);
    }, []);

    const onKeyUp = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            void onChange();
        }
    }, []);

    const onChange = useCallback(async () => {
        if (inputRef.current?.value.length) {
            inputRef.current.blur();
            const value: string = inputRef.current.value.trim();
            await appStore.setFileUrl(value);
            window.history.pushState('', '', '?url=' + encodeURIComponent(value));
            await appStore.fetchUrlInfo();
        }
    }, []);

    const handleInput = useCallback((event: React.FormEvent<HTMLInputElement>): void => {
        const element = event.target as HTMLInputElement;
        setHasChanged(element.value.length > 0);
    }, []);

    const colorBackground: string = hasChanged ? theme.palette.secondary.main : 'transparent';
    const colorArrow = hasChanged ? 'white' : 'gray';

    const buttonAction = (
        <div
            style={{ backgroundColor: colorBackground, borderLeft: '1px gainsboro solid' }}
            className={classes.action}
            onClick={onChange}
        >
            <ArrowForwardIosIcon
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: '16px',
                    transform: 'translate(-50%, -50%)',
                    margin: 'unset',
                    color: colorArrow,
                }}
                className={classes.icon}
            />
        </div>
    );

    const input = (
        <Paper elevation={2}>
            <div className={classes.correct}>
                <Input
                    onInput={handleInput}
                    inputRef={setInput}
                    name="url"
                    placeholder={t('Paste your public file link here (e.g. Dropbox)')}
                    autoComplete="on"
                    autoCorrect="off"
                    autoCapitalize="off"
                    fullWidth={true}
                    className={classes.input}
                    startAdornment={<LinkIcon className={classes.icon} />}
                    endAdornment={buttonAction}
                    disableUnderline={true}
                    defaultValue={''}
                    inputProps={{
                        className: classes.inputInput,
                        onFocus: onFocus,
                        onKeyUp: onKeyUp,
                    }}
                />
            </div>
        </Paper>
    );

    return (
        <Wrapper className="intro">
            <img src={logo} className={classes.logo} alt="Logo" />
            <h2 className={classes.welcome}>{t('Welcome to impress!')}</h2>
            {input}
            <div style={{ textAlign: 'right', marginTop: 10 }}>
                <a
                    href=""
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.preventDefault();
                        TelemetryTrackEvent('About');
                        appStore.setwizard(true);
                    }}
                >
                    {t('About')}
                </a>
            </div>
            <AboutSlider
                open={appStore.state.wizard}
                handleClose={() => appStore.setwizard(false)}
            />
        </Wrapper>
    );
};

export default UrlComp;

import type { LoadingCircleProps } from './LoadingTypes';
import type * as React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';

import { useLoadingStyles } from './LoadingStyles';

export const LoadingCircle: React.FC<LoadingCircleProps> = (props) => {
    const { classes, cx } = useLoadingStyles();

    const {
        noEvents,
        modal,
        show,
        backdropVisible,
        ModalProps,
        withTransition,
        color,
        size,
        align,
        className,
        style
    } = props;

    if (modal) {
        return (
            <Modal
                open={show}
                slotProps={{ backdrop: { invisible: backdropVisible !== true, ...ModalProps?.BackdropProps }}}
                style={noEvents ? { pointerEvents: 'none' } : undefined}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                className={ModalProps?.className}
            >
                <div
                    className={cx(
                        className,
                        `${classes.content} ${classes.content}-${align ?? 'default'}`,
                        withTransition ? classes.withTransition : undefined
                    )}
                    style={style}
                >
                    <CircularProgress tabIndex={-1} color={color} size={size} disableShrink={true}/>
                </div>
            </Modal>
        );
    }

    if (show) {
        return (
            <div
                className={cx(
                    className,
                    `${classes.content} ${classes.content}-${align ?? 'default'}`,
                    withTransition ? classes.withTransition : undefined
                )}
                style={{
                    width: size,
                    height: size,
                    ...style,
                    ...(noEvents ? { pointerEvents: 'none' } : {})
                }}
            >
                <CircularProgress
                    tabIndex={-1}
                    size={size}
                    color={color}
                    disableShrink={true}
                />
            </div>
        );
    }

    return null;
};
import type * as React from 'react';

import Snackbar from '@mui/material/Snackbar';

import ToastStore from '../stores/ToastStore';

const Toast: React.FC = () => {
    const {message} = ToastStore.state;
    return (
        <Snackbar
            autoHideDuration={3000}
            open={ToastStore.isOpen()}
            onClose={() => ToastStore.close()}
            message={<span>{message}</span>}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        />
    );
};

export default Toast;

import type { TypographyProps } from '@mui/material/Typography';
import type * as React from 'react';

import Typography from '@mui/material/Typography';

type PlaceholderTitleProps = Omit<TypographyProps, 'variant' | 'align' | 'style'>;

export const PlaceholderTitle: React.FC<PlaceholderTitleProps> = (props: PlaceholderTitleProps) => {
    return (
        <Typography
            {...props}
            style={{ marginBottom: 8, maxWidth: '100%' }}
            variant="h5"
            align="center"
        />
    );
};
import type { MakeStylesHook, StyleRules} from '@egr/xbox/utils/tss-react';
import type * as React from 'react';

import { observer } from 'mobx-react';

import { iPhoneXQueries, safeArea } from '@egr/xbox/egr-gui-elements/Helper/StyleHelper';
import { LoadingCircle } from '@egr/xbox/egr-gui-elements/LoadingAnimation/LoadingCircle';
import { ProgressManager } from '@egr/xbox/progress-manager/ProgressManager';
import { getEnvBoolean } from '@egr/xbox/utils/ReactScriptHelper';
import { makeStyles } from '@egr/xbox/utils/tss-react';

type DelayedLoadingTileClasses = 'modal' | 'backdrop';

function getDelayedLoadingTileStyles(): StyleRules<{}, DelayedLoadingTileClasses> {
    return {
        modal: {
            backgroundColor: getEnvBoolean('DEBUG_DELAYED_LOADING_TILE') ? '#ff0000' : undefined,
            opacity: getEnvBoolean('DEBUG_DELAYED_LOADING_TILE') ? 0.3 : undefined,
            ...safeArea('bottom', 'bottom', 56),
            ...iPhoneXQueries({...safeArea('bottom', 'bottom', 48)}),
        },
        backdrop: {
            position: 'absolute'
        }
    };
}

const useDelayedLoadingTileStyles: MakeStylesHook<DelayedLoadingTileClasses> = makeStyles({ name: 'DelayedLoadingTile' })(getDelayedLoadingTileStyles);
export const DelayedLoadingTile: React.FC = observer(
    () => {
        const { classes } = useDelayedLoadingTileStyles();
        return (
                <LoadingCircle
                    modal={true}
                    ModalProps={{
                        className: classes.modal,
                        BackdropProps: { className: classes.backdrop }
                    }}
                    show={ProgressManager.inProgress}
                    withTransition={true}
                    size={40}
                    color={'secondary'}
                />
        );
    }
);

import { SyncExternalStore } from '@egr/xbox/utils/SyncExternalStore';

interface VisibilityState {
    visibility: boolean;
}

class StoreVisibilityDialogIframe extends SyncExternalStore<VisibilityState> {
    constructor() {
        super({ visibility: false });
    }

    public getVisibility(): boolean {
        return this.snapshot.visibility;
    }

    public setVisibility(visibility: boolean): void {
        this.updateState({ visibility });
    }
}

export const visibilityDialogIframeStore = new StoreVisibilityDialogIframe();

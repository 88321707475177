import type { Theme, ThemeOptions } from '@mui/material/styles';

import { createTheme } from '@mui/material/styles';

import { getBaseTheme, wcfGUIPalette, wcfGUITypography } from '@egr/xbox/egr-gui-elements/Helper/MUIOverrides';
import { OpenSansFontFaces } from '@egr/xbox/fonts/openSans/OpenSansImport';

export const mainPrimaryColor: string = '#4F4F4F';
export const mainSecondaryColor: string = '#F7A600';

const baseThemeLight: Theme = getBaseTheme(wcfGUIPalette(mainPrimaryColor, mainSecondaryColor));

const defaultThemeOptions: ThemeOptions = {
    ...baseThemeLight,
    typography: wcfGUITypography,
    components: {
        MuiCssBaseline: {
            styleOverrides: OpenSansFontFaces,
        },

        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                },
            },
        },
        MuiMobileStepper: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                },
                dots: {
                    margin: '0 10px',
                },
            },
        },
    },
};

export default createTheme(defaultThemeOptions);

//eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { DialogProps as MUIDialogProps } from '@mui/material/Dialog';

//eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MUIDialog from '@mui/material/Dialog';
import { observable, makeObservable, computed, action } from 'mobx';
import * as React from 'react';

import { DialogContext } from '@egr/xbox/egr-gui-dialogs/context/DialogContext';
import { SafeAreaBottomSpacer } from '@egr/xbox/egr-gui-elements/single-components/SafeAreaBottomSpacer';
import { getUUID } from '@egr/xbox/utils/Helper';
import { android } from '@egr/xbox/utils/UserAgent';

export type DialogProps = MUIDialogProps;

class DialogTracker {
    @observable
    private _openDialogs = new Set();

    @observable
    private _openFullscreenDialogs = new Set();

    @computed
    public get dialogOpen(): boolean {
        return this._openDialogs.size > 0;
    }

    @computed
    public get fullscreenDialogOpen(): boolean {
        return this._openFullscreenDialogs.size > 0;
    }

    constructor() {
        makeObservable(this);
    }

    @action
    public updateDialogTracker(id: string, open: boolean, fullscreen: boolean): void {
        if (open) {
            this._openDialogs.add(id);
            if (fullscreen) {
                this._openFullscreenDialogs.add(id);
            }
            return;
        }
        this._openDialogs.delete(id);
        this._openFullscreenDialogs.delete(id);
    }
}

export const dialogTracker = new DialogTracker();

export function useDialogTracker(open: boolean, fullscreen: boolean): void {
    const uuid = React.useRef(getUUID());
    React.useEffect(
        () => {
            dialogTracker.updateDialogTracker(uuid.current, open, fullscreen);
            return () => {
                dialogTracker.updateDialogTracker(uuid.current, false, false);
            };
        },
        [
            open,
            fullscreen
        ]
    );
}

export const MaxSizeDialogPaperProps = { sx: { width: '100%', height: '100%' }};

const Dialog: React.FC<DialogProps> = (props) => {
    const uuid = React.useRef<string>(getUUID());

    const { children, ...dialogProps } = props;

    React.useEffect(
        () => {
            dialogTracker.updateDialogTracker(uuid.current, props.open, props.fullScreen ?? false);
            return () => { dialogTracker.updateDialogTracker(uuid.current, false, false); };
        },
        [
            props.open,
            props.fullScreen,
            uuid,
        ]
    );
    return (
        <DialogContext.Provider
            value={{
                touchRipple: !android,
                fullscreen: props.fullScreen,
            }}
        >
            <MUIDialog {...dialogProps}>
                {children}
                {props.fullScreen ? <SafeAreaBottomSpacer/> : null}
            </MUIDialog>
        </DialogContext.Provider>
    );
};

export default Dialog;
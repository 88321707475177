import type { StyleRules, MakeStylesHook} from '@egr/xbox/utils/tss-react';
import type { PaletteOptions, TypographyVariantsOptions , Components, Theme, Breakpoint } from '@mui/material/styles';
import type { CSSObject } from 'tss-react';

import Fade from '@mui/material/Fade';
import { alpha, darken, lighten , createTheme} from '@mui/material/styles';
import merge from 'lodash/merge';

import { makeStyles } from '@egr/xbox/utils/tss-react';

export const defaultBreakpoints: { [key in Breakpoint]: number } = {
    xs: 360,
    sm: 568, // screen-height iPhone 5
    md: 960,
    lg: 1280,
    xl: 1920
};

export function getBaseTheme(
    palette: PaletteOptions,
    breakpointValues: { [key in Breakpoint]: number } = defaultBreakpoints,
    spacing: number = 8,
    typography: TypographyVariantsOptions = wcfGUITypography
): Theme {
    return createTheme({
        palette,
        spacing,
        breakpoints: {
            values: breakpointValues
        },
        typography
    });
}

export function wcfGUIOverrides(
    theme: Theme,
): Components {
    return {
        MuiPopover: {
            defaultProps: {
                TransitionComponent: Fade
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    width: '100%',
                    height: '100%',
                    WebkitFontSmoothing: 'antialiased',
                    MozOsxFontSmoothing: 'grayscale',
                    WebkitTapHighlightColor: 'rgba(0, 0, 0, .3)',
                    boxSizing: 'border-box',
                },
                '*, *::before, *::after': {
                    boxSizing: 'inherit'
                },
                body: {
                    backgroundColor: theme.palette.background.paper,
                    width: '100%',
                    height: '100%',
                    WebkitTextSizeAdjust: '100%',
                    MozTextSizeAdjust: '100%',
                    MsTextSizeAdjust: '100%',
                    WebkitOverflowScrolling: 'touch',
                    color: theme.palette.text.primary,
                    margin: 0,
                    overflow: 'hidden',
                    '&.ar-enabled': {
                        background: 'transparent !important',
                    },
                    '& input::-ms-clear': {
                        display: 'none'
                    },
                    '& .wcf-core.pointer-feedback': {
                        backgroundColor: alpha(theme.palette.primary.main, .5),
                        '&::after': {
                            backgroundColor: theme.palette.primary.main,
                        }
                    },
                    userSelect: 'none',
                    WebkitTouchCallout: 'none',
                    '& input': {
                        userSelect: 'auto',
                    },
                    '& textarea': {
                        userSelect: 'auto',
                    }
                }
            }
        },
        MuiModal: {
            defaultProps: {
                disableAutoFocus: true,
                disableEnforceFocus: true,
                disableRestoreFocus: true
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid transparent',
                }
            }
        },
        MuiMobileStepper: {
            styleOverrides: {
                positionStatic: {
                    position: 'relative',
                },
                dots: {
                    position: 'absolute',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    top: '50%'
                },
                dotActive: {
                    backgroundColor: theme.palette.primary.main
                },
                positionBottom: {
                    bottom: 'var(--safeareabottom)',
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    marginDense: {
                        ['&.$inputAdornedEnd}']: {
                            paddingRight: 0,
                        }
                    },
                    input: {
                        overflow: 'hidden'
                    }
                },
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: ( { ownerState, theme } ) => ({
                    ...(!ownerState.disableGutters && {
                        [(theme as Theme).breakpoints.up('sm')]: {
                            paddingLeft: (theme as Theme).spacing(3),
                            ...(!ownerState.secondaryAction && {
                                paddingRight: (theme as Theme).spacing(3),
                            })
                        }
                    }),
                }),
                secondaryAction: {
                    [theme.breakpoints.up('sm')]: {
                        paddingRight: theme.spacing(7.75)
                    }
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: ( { ownerState, theme } ) => ({
                    ...(ownerState.selected && {
                        backgroundColor: (theme as Theme).palette.action.selected
                    }),
                    ...(!ownerState.disableGutters && {
                        [(theme as Theme).breakpoints.up('sm')]: {
                            paddingLeft: (theme as Theme).spacing(3),
                            ...(!ownerState.secondaryAction && {
                                paddingRight: (theme as Theme).spacing(3),
                            })
                        }
                    }),
                    ...(!ownerState.disabled && {
                        cursor: 'pointer'
                    })
                }),
            }
        },
        MuiFab: {
           variants: [
                {
                    props: { color: 'default' },
                    style: {
                        color: '#868686',
                        backgroundColor: '#DADADA'
                    }
                },
                {
                    props: { color: 'secondary'},
                    style: {
                        color: '#FFFFFF',
                        backgroundColor: '#868686',
                        '.wcf-hover-enabled &:hover': {
                            backgroundColor: theme.palette.secondary.main,
                            '@media (hover: none)': {
                                backgroundColor: '#868686',
                            }
                        }
                    }
                }
            ]
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    '&:first-of-type': {
                        marginRight: 0,
                    }
                },
            },
            variants: [
                {
                    props: { role: 'button' },
                    style: { cursor: 'pointer' }
                }
            ]
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    '& span': {
                        display: 'block'
                    }
                },
            }
        },
        MuiAvatar: {
            defaultProps: {
                children: ''
            },
            styleOverrides: {
                root: {
                    transform: 'translate3d(0,0,0)',  // fixes 2nd bug in iOS
                    '& > img': {
                        borderRadius: 'inherit', // fixes bug in iOS
                    }
                },
                square: {
                    borderRadius: 4,
                },
                colorDefault: {
                    backgroundColor: '#ECECEC'
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: 'inherit',
                        opacity: .3
                    }
                },
                edgeStart: ({ ownerState: { size }}) => {
                    return size === 'small' ? {
                        marginRight: 3
                    } : {
                        marginRight: 12,
                    };
                },
            }
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    color: '#868686'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    lineHeight: '20px'
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    '&::before': {
                        borderBottom: '1px solid #DADADA'
                    },
                    '.wcf-hover-enabled &:hover:not(.$disabled)::before': {
                        borderBottom: '2px solid #DADADA',
                        '@media (hover: none)': {
                            borderBottom: '1px solid #DADADA'
                        }
                    },
                }
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                positionEnd: {
                    ['&.$marginDense}']: {
                        marginRight: -16
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    padding: '1px 0 0 0' // fixes issue in FF
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                    root: {
                    fontSize: 14,
                    lineHeight: '24px' // changing this will misposition the outlined-inut label
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paperAnchorBottom: {
                    maxHeight: '50%'
                },
                paperAnchorLeft: {
                    maxWidth: 'calc(100vw - 56px)',
                },
                paperAnchorDockedLeft: {
                    maxWidth: 'calc(100vw - 56px)',
                },
                paperAnchorDockedRight: {
                    maxWidth: 'calc(100vw - 56px)',
                },
                paperAnchorRight: {
                    maxWidth: 'calc(100vw - 56px)',
                }
            }
        },
        MuiSnackbar: {
            styleOverrides: {
                anchorOriginBottomCenter: {
                    ['@media (min-width: 960px)']: {
                        bottom: 20
                    }
                },
            }
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary.main
                },
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: ({ ownerState: { fullScreen} }) => {
                    return fullScreen ? {
                        ['@media (min-width: 400px)']: {
                            minWidth: 360,
                        }
                    } : {
                        maxHeight: '65%',
                        [theme.breakpoints.up('md')]: {
                            maxHeight: '80%',
                        },
                        ['@media (min-width: 400px)']: {
                            minWidth: 360,
                        }
                    };
                },
            }
        },
        MuiButton: {
            variants: [
                {
                    props: { color: 'primary', disabled: true },
                    style: { color: alpha(theme.palette.primary.main, .3) }
                }
            ]
        },
        MuiTypography: {
            styleOverrides: {
                noWrap: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block'
                },
                caption: {
                    color: theme.palette.text.secondary
                }
            }
        },
        MuiListItemSecondaryAction: {
            styleOverrides: {
                root: {
                    color: '#868686',
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    backgroundColor: '#FFFFFF',
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none !important'
                }
            }
        },
    };
}

export function wcfGUIDarkOverrides(
    theme: Theme
): Components {
    return merge(
        wcfGUIOverrides(theme),
        {
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: '#868686'
                    },
                }
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: '#DADADA'
                    },
                    colorSecondary: {
                        ['&.$disabled}']: {
                            color: '#868686',
                            opacity: '1 !important'
                        }
                    }
                }
            },
            MuiListItem: {
                styleOverrides: {
                    root: ( { ownerState, theme } ) => ({
                        ...(!ownerState.disableGutters && {
                            [(theme as Theme).breakpoints.up('sm')]: {
                                paddingLeft: (theme as Theme).spacing(3),
                                ...(!ownerState.secondaryAction && {
                                    paddingRight: (theme as Theme).spacing(3),
                                })
                            }
                        }),
                        ...(ownerState.selected && {
                            backgroundColor: '#4F4F4F', // action.selected
                            '.wcf-hover-enabled &:hover': {
                                backgroundColor: '#4F4F4F'
                            }
                        }),
                    }),
                }
            },
            MuiAvatar: {
                styleOverrides: {
                    colorDefault: {
                        backgroundColor: '#4F4F4F'
                    }
                }
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: '#B2B2B2'
                    },
                    colorSecondary: {
                        color: '#868686'
                    }
                }
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        color: '#B2B2B2'
                    }
                }
            },
            MuiInput: {
                styleOverrides: {
                    underline: {
                        '&:before': {
                            borderBottom: '1px solid #4F4F4F'
                        },
                        '.wcf-hover-enabled &:hover:not(.$disabled):before': {
                            borderBottom: '2px solid #4F4F4F',
                            '@media (hover: none)': {
                                borderBottom: '1px solid #4F4F4F'
                            }
                        },
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.background.default,
                    }
                }
            },
            MuiSnackbarContent: {
                styleOverrides: {
                    root: {
                        color: '#FFF'
                    }
                }
            },
            MuiMobileStepper: {
                styleOverrides: {
                    dotActive: {
                        backgroundColor: '#B2B2B2'
                    }
                }
            },
            MuiBadge: {
                styleOverrides: {
                    colorSecondary: {
                        color: '#DADADA',
                        backgroundColor: '#4F4F4F'
                    }
                }
            }
        }
    );
}

export const wcfGUITypography: TypographyVariantsOptions & { fontFamily: string } = {
    fontFamily: '"Open Sans", sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    body1: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
    },
    body2: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px'
    },
    subtitle1: { // default-size is 16px
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px'
    },
    subtitle2: { // default-size is 14px
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '20px'
    },
    caption: { // default-size is 12px
        fontSize: 13,
        lineHeight: '17px',
    },
    h3: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
    },
    h4: {
        fontSize: 35
    },
    h5: {
        lineHeight: '30px'
    },
    h6: {
        fontWeight: 400,
    },
};

export function wcfGUIPalette(
    mainPrimaryColor: string,
    mainSecondaryColor: string,
): PaletteOptions {
    return {
        mode: 'light',
        primary: {
            contrastText: '#ffffff',
            light: lighten(mainPrimaryColor, .1),
            main: mainPrimaryColor,
            dark: darken(mainPrimaryColor, .07),
        },
        secondary: {
            contrastText: '#ffffff',
            light: lighten(mainSecondaryColor, .1),
            main: mainSecondaryColor,
            dark: darken(mainSecondaryColor, .07),
        },
        text: {
            primary: '#4F4F4F',
            secondary: '#B2B2B2',
            disabled: '#DADADA',
        },
        background: {
            default: '#FFFFFF'
        },
        action: {
            active: '#868686',
            disabled: 'rgba(134,134,134,0.3)',
            selected: '#ECECEC'
        },
        divider: '#ECECEC',
        grey: {
            50: '#868686',
            100: '#F9F9F9',
            300: '#F2F2F2',
            500: '#ECECEC',
            600: '#DADADA',
            700: '#B2B2B2',
            900: '#868686',
        },
        error: {
            light: '#bf1900',
            main: '#bf1900',
            contrastText: '#FFFFFF'
        }
    };
}

export function wcfGUIDarkPalette(
    mainPrimaryColor: string,
    mainSecondaryColor: string,
): PaletteOptions {
    return {
        mode: 'dark',
        primary: {
            contrastText: '#ffffff',
            light: lighten(mainPrimaryColor, .1),
            main: mainPrimaryColor,
            dark: darken(mainPrimaryColor, .07),
        },
        secondary: {
            contrastText: '#ffffff',
            light: lighten(mainSecondaryColor, .1),
            main: mainSecondaryColor,
            dark: darken(mainSecondaryColor, .07),
        },
        text: {
            primary: '#DADADA',
            secondary: '#868686',
            disabled: '#868686',
        },
        background: {
            default: '#3A3A3A',
            paper: '#3A3A3A'
        },
        action: {
            active: '#DADADA',
            disabled: 'rgba(134,134,134,0.3)',
            selected: '#ECECEC'
        },
        divider: '#4F4F4F',
        grey: {
            50: '#B2B2B2', // reverted text.secondary
            100: '#4F4F4F',
            300: '#4F4F4F',
            500: '#3A3A3A',
            600: '#DADADA',
            700: '#868686',
            900: '#2A2A2A',
        },
        error: {
            light: '#ed1c24',
            main: '#ed1c24',
            contrastText: '#FFFFFF',
        }
    };
}

function getFontStyles(theme: Theme): StyleRules<{}, 'h3'> {
    return {
        h3: {
            ...(theme.typography.h3 as CSSObject),
            lineHeight: '22px',
            marginTop: 3,
        }
    };
}

/**
 * Provides special font-layouts
 */
export const fontStylesHook: MakeStylesHook<'h3'> = makeStyles({ name: 'fontStyles' })(getFontStyles);
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import CustomMobileStepper from './CustomMobileStepper';
import Slide from './Slide';
import { images } from './SliderUtils';

import Dialog from '@egr/xbox/egr-gui-dialogs/utils/DialogOverride';

const AboutSlider: React.FC<{ open: boolean, handleClose: () => void }> = ({ open, handleClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const sliderRef = useRef<Slider | null>(null);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const handleNext = () => sliderRef.current?.slickNext();
  const handleBack = () => sliderRef.current?.slickPrev();
  const handleDialogClose = () => {
    setActiveStep(0);
    handleClose();
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current: number) => setActiveStep(current),
    arrows: false,
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullScreen={isSmallScreen}
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: isSmallScreen ? '100%' : 700,
          margin: 0,
        },
      }}
    >
      <DialogContent
        sx={{
          padding: 0,
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Slider {...settings} ref={sliderRef}>
            {images.map((step, index) => (
              <Slide
                key={index}
                imgPath={step.imgPath}
                title={step.title}
                text={step.text}
              />
            ))}
          </Slider>
        </div>
        <CustomMobileStepper
          steps={images.length}
          activeStep={activeStep}
          onBack={handleBack}
          onNext={handleNext}
          onClose={handleDialogClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AboutSlider;
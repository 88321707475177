import { getEnvBoolean, getEnvVar } from '@egr/xbox/utils/ReactScriptHelper';

export const enum DialogType {
    DEFAULT = 'default',
    SIMPLE = 'simple',
    LOADING = 'loading',
    COLOR = 'color',
    ERROR = 'error',
    QR = 'qr',
    ALL = 'all'
}

export const FEATURE_YOUTUBE_WARNING: boolean = getEnvBoolean('FEATURE_YOUTUBE_WARNING', false);

export const ColorDialogColors: Array<string> = [
    '#8F8F8F', '#9E7561', '#BA7066', '#D84C2D', '#DB6344', '#E27D44',
    '#EAB158', '#EFD375', '#F5EA90', '#BEDB7B', '#98CF5D', '#5EA56C',
    '#83D398', '#ADDFC2', '#B6DFE6', '#ACC5E4', '#6484E0', '#9E9BF8',
    '#B39AF8', '#9C7BDC', '#BB77E0', '#E096B1', '#C2A7AC', '#C6BDBF',
];

export const ColorDialogColorsExtended: Array<string> = [
    '#FFFFFE', '#EBEAEC', '#D6D6D6', '#C2C2C2', '#ADADAD', '#999999', '#858585', '#707070', '#5C5C5C', '#474747', '#333333', '#000000',
    '#00374B', '#001D57', '#12053B', '#2E053D', '#3C071B', '#5C0702', '#591C00', '#583300', '#563E00', '#656100', '#505505', '#263D0F',
    '#014D65', '#012F7C', '#1A0A51', '#450C59', '#541029', '#821100', '#7B2901', '#7A4A00', '#785801', '#8D8602', '#6F770A', '#38571B',
    '#016E8F', '#0142AA', '#2D0977', '#60187C', '#791A3C', '#B51900', '#AE3E00', '#A96800', '#A57B02', '#C4BC01', '#9BA50E', '#4E7927',
    '#028CB5', '#0156D7', '#381A94', '#7A229D', '#992450', '#E22400', '#DA5100', '#D48202', '#D29D01', '#F6EC00', '#C3D217', '#669D35',
    '#00A1D8', '#0061FE', '#4D21B2', '#982BBC', '#B92D5C', '#FF4014', '#FE6A00', '#FFAB02', '#FCC700', '#FFFB40', '#D9EC38', '#76BA3F',
    '#02C7FC', '#3B87FE', '#5E30EB', '#BE37F3', '#E53B7A', '#FF6251', '#FF8549', '#FFB43F', '#FECB3E', '#FEF76B', '#E4EF65', '#97D45F',
    '#52D6FB', '#74A7FF', '#854FFD', '#D257FF', '#EF729E', '#FE8C82', '#FEA57D', '#FFC778', '#FFD978', '#FEF993', '#EAF28F', '#B2DD8B',
    '#93E3FE', '#A7C6FF', '#B18CFE', '#E192FF', '#F4A4C1', '#FEB5AF', '#FEC5AA', '#FFD9A8', '#FEE4A9', '#FEFCBA', '#F2F7B7', '#CDE8B5',
    '#CBF0FF', '#D3E2FF', '#DAC9FF', '#EFCAFE', '#F9D3E0', '#FEDBD7', '#FEE2D6', '#FEECD4', '#FFF2D5', '#FFFCDD', '#F7FADB', '#E0EED5',
];

// Deprecated, use TXT instead
export const LEGAL_URL_IFRAME: string = getEnvVar('LEGAL_URL_IFRAME', 'https://legal-information.pcon-solutions.com/v1/app/<LANGUAGE>/responsive-iframe/terms-of-use+privacy-policy.html');

export const IMPRINT_URL_IFRAME: string = getEnvVar('IMPRINT_URL_IFRAME', 'https://legal-information.pcon-solutions.com/v1/app/<LANGUAGE>/responsive-iframe/address-information+commercial-information.html');

export const LEGAL_NOTICE_URL_TXT: string = getEnvVar('LEGAL_URL_IFRAME', 'https://legal-information.pcon-solutions.com/v1/app/<LANGUAGE>/responsive-iframe/address-information+commercial-information.txt');
export const LEGAL_DOCUMENTS_URL_TXT: string = getEnvVar('LEGAL_URL_IFRAME', 'https://legal-information.pcon-solutions.com/v1/app/<LANGUAGE>/responsive-iframe/terms-of-use+privacy-policy.txt');
export const TERMS_OF_USE_TXT: string = getEnvVar('TERMS_OF_USE_IFRAME', 'https://legal-information.pcon-solutions.com/v1/app/<LANGUAGE>/responsive-iframe/terms-of-use.txt');
export const DATA_PRIVACY_TXT: string = getEnvVar('DATA_PRIVACY_IFRAME', 'https://legal-information.pcon-solutions.com/v1/app/<LANGUAGE>/responsive-iframe/privacy-policy.txt');

export const DETAILS_IN_ERRORDIALOG: boolean = getEnvBoolean('DETAILS_IN_ERRORDIALOG', false);

export const enum ErrorImageType {
    DEFAULT,
    SESSION_TIMEOUT
}